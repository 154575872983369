import React from 'react'

export const IconSelectMultiple: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        id="box"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09555 6.19189H17.227C17.5482 6.19189 17.8079 6.45152 17.8079 6.77272V14.9042C17.8079 15.2254 17.5482 15.485 17.227 15.485H9.09555C8.77435 15.485 8.51473 15.2254 8.51473 14.9042V6.77272C8.51473 6.45152 8.77435 6.19189 9.09555 6.19189ZM9.67629 14.3234H16.6461V7.35353H9.67629V14.3234ZM7.35305 9.67684H6.19141V17.2275C6.19141 17.5487 6.45103 17.8083 6.77223 17.8083H14.3229V16.6467H7.35305V9.67684ZM12.6013 13.1511C12.5926 13.1516 12.584 13.1516 12.5754 13.1516C12.4228 13.1516 12.276 13.0918 12.1683 12.9829L10.7289 11.5435L11.543 10.7294L12.5368 11.7232L15.012 8.75284L15.8969 9.49039L13.0181 12.9449C12.9139 13.0687 12.7631 13.1436 12.6013 13.1511Z"
      />
      <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="6" y="6" width="12" height="12">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.09555 6.19189H17.227C17.5482 6.19189 17.8079 6.45152 17.8079 6.77272V14.9042C17.8079 15.2254 17.5482 15.485 17.227 15.485H9.09555C8.77435 15.485 8.51473 15.2254 8.51473 14.9042V6.77272C8.51473 6.45152 8.77435 6.19189 9.09555 6.19189ZM9.67629 14.3234H16.6461V7.35353H9.67629V14.3234ZM7.35305 9.67684H6.19141V17.2275C6.19141 17.5487 6.45103 17.8083 6.77223 17.8083H14.3229V16.6467H7.35305V9.67684ZM12.6013 13.1511C12.5926 13.1516 12.584 13.1516 12.5754 13.1516C12.4228 13.1516 12.276 13.0918 12.1683 12.9829L10.7289 11.5435L11.543 10.7294L12.5368 11.7232L15.012 8.75284L15.8969 9.49039L13.0181 12.9449C12.9139 13.0687 12.7631 13.1436 12.6013 13.1511Z"
          fill="white"
        />
      </mask>
    </svg>
  )
}
