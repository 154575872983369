import React from 'react'

export const IconArchive: React.FC = () => {
  return (
    <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3898 5.40095L29.5884 9.50347C29.7192 9.63029 29.8229 9.78124 29.8936 9.94755C29.9643 10.1139 30.0004 10.2922 30 10.4723V28.2632C30 29.7725 28.7442 31 27.2 31H4.8C3.2558 31 2 29.7725 2 28.2632V10.4723C1.99958 10.2922 2.03575 10.1139 2.1064 9.94755C2.17706 9.78124 2.2808 9.63029 2.4116 9.50347L6.6102 5.40095C6.73999 5.27363 6.89426 5.17265 7.06413 5.10384C7.234 5.03503 7.41612 4.99974 7.6 5H24.4C24.5839 4.99974 24.766 5.03503 24.9359 5.10384C25.1057 5.17265 25.26 5.27363 25.3898 5.40095ZM23.8204 7.73684H8.1796L6.781 9.1039H25.219L23.8204 7.73684ZM4.8 11.8407V28.2632H27.2028L27.2 11.8407H4.8ZM11.7 17.6897H19.8V15H22.5V20.3793H9V15H11.7V17.6897Z"
      />
    </svg>
  )
}
