import React from 'react'

export const IconSlack: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      //TODO:check if needed
      // style="fill:rgba(0, 0, 0, 0);transform:-ms-filter;"
    >
      <path d="M20.935,12.646c-0.28-0.842-1.181-1.3-2.022-1.034l-1.632,0.532c-0.355-1.099-0.735-2.268-1.092-3.365 c0.001-0.001,0.003-0.001,0.006-0.002c-0.001-0.002-0.003-0.006-0.004-0.008c0.983-0.318,1.613-0.523,1.613-0.523 c0.842-0.281,1.3-1.182,1.035-2.023c-0.281-0.842-1.184-1.3-2.025-1.034l-1.621,0.527c-0.315-0.976-0.519-1.604-0.519-1.604 c-0.281-0.842-1.181-1.3-2.024-1.034c-0.842,0.28-1.299,1.181-1.033,2.024l0.522,1.609L8.771,7.803 c-0.318-0.98-0.524-1.611-0.524-1.611c-0.279-0.841-1.182-1.3-2.022-1.034C5.382,5.438,4.924,6.34,5.191,7.181l0.524,1.616 L4.053,9.338c-0.782,0.281-1.225,1.138-0.988,1.95c0.25,0.856,1.152,1.373,1.979,1.092c0.006,0,0.658-0.209,1.665-0.536l1.099,3.386 l-0.002,0v0.002l-1.67,0.545c-0.782,0.28-1.225,1.137-0.987,1.949c0.25,0.857,1.15,1.374,1.979,1.093 c0.007,0,0.659-0.211,1.665-0.538l0.003,0.005c0.002,0,0.006-0.001,0.008-0.002l0.539,1.657c0.281,0.783,1.138,1.226,1.949,0.989 c0.857-0.25,1.373-1.151,1.094-1.979c0-0.006-0.209-0.654-0.533-1.654l0,0c-0.001-0.003-0.002-0.006-0.003-0.009 c1.104-0.358,2.276-0.739,3.376-1.098l0.543,1.668c0.28,0.782,1.138,1.225,1.949,0.989c0.856-0.251,1.374-1.152,1.092-1.979 c0-0.007-0.209-0.659-0.535-1.663c0.007-0.002,0.013-0.004,0.019-0.006c-0.001-0.002-0.002-0.005-0.003-0.007 C19.27,14.875,19.9,14.67,19.9,14.67C20.743,14.389,21.201,13.488,20.935,12.646z M10.86,14.238 c-0.359-1.102-0.739-2.277-1.097-3.377c0.002,0,0.005-0.001,0.005-0.001c0-0.002,0-0.004,0-0.006 c1.098-0.356,2.268-0.735,3.363-1.092l1.098,3.377L10.86,14.238z"></path>
    </svg>
  )
}
