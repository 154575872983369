import React from 'react'

export const Alexa: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 173.25333 173.33333"
      height="173.33333"
      width="173.25333"
      xmlSpace="preserve"
      id="svg2"
      version="1.1"
    >
      <defs id="defs6">
        <clipPath id="clipPath22" clipPathUnits="userSpaceOnUse">
          <path id="path20" d="M 0,0 H 1299 V 1300 H 0 Z" />
        </clipPath>
        <clipPath id="clipPath28" clipPathUnits="userSpaceOnUse">
          <path
            style={{ clipRule: 'evenodd' }}
            id="path26"
            d="M 649.672,1300 C 290.871,1300 -0.00390625,1008.98 -0.00390625,650 -0.00390625,320.398 245.207,48.1289 563.055,5.76172 V 136.738 c 0,36.934 -23.282,70.133 -58.274,81.871 -183.39,61.629 -314.582,237.016 -309.754,442.352 5.883,249.992 209.418,446.539 459.336,444.019 249.004,-2.52 450.087,-205.257 450.087,-454.98 0,-5.691 -0.14,-11.355 -0.35,-16.992 -0.05,-1.262 -0.09,-2.528 -0.14,-3.797 -0.24,-5.234 -0.56,-10.461 -0.97,-15.656 -0.13,-1.653 -0.29,-3.301 -0.44,-4.942 -0.32,-3.633 -0.69,-7.226 -1.1,-10.82 -0.46,-4.094 -0.99,-8.145 -1.56,-12.188 -0.25,-1.843 -0.5,-3.691 -0.78,-5.527 C 1043.43,213.121 565.727,6.87109 563.109,5.75 591.434,1.98047 620.324,0 649.672,0 c 358.808,0 649.678,291.012 649.678,650 0,358.98 -290.87,650 -649.678,650"
          />
        </clipPath>
      </defs>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,173.33333)" id="g10">
        <g transform="scale(0.1)" id="g12">
          <path
            id="path14"
            style={{ fillOpacity: 1, fillRule: 'evenodd', stroke: 'none' }}
            d="M 649.672,1300 C 290.871,1300 -0.00390625,1008.98 -0.00390625,650 -0.00390625,320.398 245.207,48.1289 563.055,5.76172 V 136.738 c 0,36.934 -23.282,70.133 -58.274,81.871 -183.39,61.629 -314.582,237.016 -309.754,442.352 5.883,249.992 209.418,446.539 459.336,444.019 249.004,-2.52 450.087,-205.257 450.087,-454.98 0,-5.691 -0.14,-11.355 -0.35,-16.992 -0.05,-1.262 -0.09,-2.528 -0.14,-3.797 -0.24,-5.234 -0.56,-10.461 -0.97,-15.656 -0.13,-1.653 -0.29,-3.301 -0.44,-4.942 -0.32,-3.633 -0.69,-7.226 -1.1,-10.82 -0.46,-4.094 -0.99,-8.145 -1.56,-12.188 -0.25,-1.843 -0.5,-3.691 -0.78,-5.527 C 1043.43,213.121 565.727,6.87109 563.109,5.75 591.434,1.98047 620.324,0 649.672,0 c 358.808,0 649.678,291.012 649.678,650 0,358.98 -290.87,650 -649.678,650"
          />
          <g id="g16">
            <g clipPath="url(#clipPath22)" id="g18">
              <g clipPath="url(#clipPath28)" id="g24">
                <path
                  id="path30"
                  style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
                  d="M -98.6406,-98.6406 H 1397.99 V 1398.64 H -98.6406 V -98.6406"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
