import React from 'react'

const Support: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="497" height="467" viewBox="0 0 497 467" fill="none">
      <g opacity="0.5" clipPath="url(#clip0_678_17906)">
        <path
          d="M248.323 164.963C259.409 164.963 268.396 155.977 268.396 144.893C268.396 133.809 259.409 124.823 248.323 124.823C237.237 124.823 228.25 133.809 228.25 144.893C228.25 155.977 237.237 164.963 248.323 164.963Z"
          fill="#D9D9D9"
        />
        <path
          d="M193 46.5031L194.958 40.1395C201.323 15.6642 222.865 0 247.834 0C277.698 0 303.646 23.4963 303.646 53.8457C303.646 78.321 287 98.8803 263.99 105.244V116.013H232.167V96.9223C232.167 86.1531 240 76.8525 250.771 75.8735C262.521 74.405 272.313 65.5938 272.313 53.8457C272.313 40.629 260.563 31.8179 247.834 31.8179C237.063 31.8179 228.25 37.692 225.313 48.4611L223.354 54.8247L193 46.5031Z"
          fill="#D9D9D9"
        />
      </g>
      <path
        d="M13.8386 402.394C36.737 364.497 -33.6863 339.984 21.9809 317.897C77.6482 295.81 286.329 314.133 286.329 314.133C286.329 314.133 434.504 290.065 483.255 336.574C507.298 359.511 493.559 363.001 488.407 393.418C485.049 413.239 496.421 421.342 474.095 429.32C427.001 446.15 394.286 425.569 356.169 434.805C318.052 444.041 284.636 455.245 259.423 460.734C236.525 465.72 209.219 470.009 144.359 464.225C61.1081 456.8 -9.05984 440.29 13.8386 402.394Z"
        fill="#F5F5F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M434.423 263.646C436.938 269.25 440.404 276.095 437.598 282.51C434.792 288.924 428.533 292.652 428.533 287.581C428.533 282.51 429.82 278.515 429.184 276.42C428.548 274.325 428.548 279.303 426.567 279.303C424.587 279.303 425.752 264.997 425.752 264.997C425.752 264.997 431.908 258.042 434.423 263.646Z"
        fill="#FFC62C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M434.423 263.646C436.938 269.25 440.404 276.095 437.598 282.51C434.792 288.924 428.533 292.652 428.533 287.581C428.533 282.51 429.82 278.515 429.184 276.42C428.548 274.325 428.548 279.303 426.567 279.303C424.587 279.303 425.752 264.997 425.752 264.997C425.752 264.997 431.908 258.042 434.423 263.646Z"
        fill="url(#paint0_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M417.495 227.697C418.317 232.221 421.347 262.144 423.724 264.672C426.793 267.937 431.902 267.937 435.25 264.672C441.595 258.485 438.994 217.81 436.543 205.223C434.093 192.637 426.12 181.042 426.12 181.042C426.12 181.042 415.483 216.625 417.495 227.697Z"
        fill="#5A76ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M417.495 227.697C418.317 232.221 421.347 262.144 423.724 264.672C426.793 267.937 431.902 267.937 435.25 264.672C441.595 258.485 438.994 217.81 436.543 205.223C434.093 192.637 426.12 181.042 426.12 181.042C426.12 181.042 415.483 216.625 417.495 227.697Z"
        fill="url(#paint1_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M417.495 227.697C418.317 232.221 421.347 262.144 423.724 264.672C426.793 267.937 431.902 267.937 435.25 264.672C441.595 258.485 438.994 217.81 436.543 205.223C434.093 192.637 426.12 181.042 426.12 181.042C426.12 181.042 415.483 216.625 417.495 227.697Z"
        fill="url(#paint2_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M401.274 406.324C386.155 403.749 378.843 400.702 378.843 394.84C378.843 390.583 387.006 388.273 393.342 389.572C393.342 389.572 413.054 400.257 414.808 400.066C414.63 405.287 412.901 408.304 401.274 406.324Z"
        fill="#5A76ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M401.274 406.324C386.155 403.749 378.843 400.702 378.843 394.84C378.843 390.583 387.006 388.273 393.342 389.572C393.342 389.572 413.054 400.257 414.808 400.066C414.63 405.287 412.901 408.304 401.274 406.324Z"
        fill="url(#paint3_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M345.694 405.871C330.574 403.296 323.262 400.249 323.262 394.387C323.262 390.74 329.255 388.522 334.956 388.778C334.891 389.483 356.609 399.406 359.243 398.81C359.198 404.518 357.904 407.95 345.694 405.871Z"
        fill="#5974E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M345.694 405.871C330.574 403.296 323.262 400.249 323.262 394.387C323.262 390.74 329.255 388.522 334.956 388.778C334.891 389.483 356.609 399.406 359.243 398.81C359.198 404.518 357.904 407.95 345.694 405.871Z"
        fill="url(#paint4_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M419.091 252.121C423.883 276.962 423.883 395.428 423.883 395.428C423.883 395.428 422.836 400.352 408.79 400.352C394.743 400.352 393.696 395.428 393.696 395.428L387.468 292.503L364.948 395.428C364.948 395.428 361.533 400.365 349.107 399.051C336.682 397.737 334.762 390.902 334.762 390.902C334.762 390.902 347.134 255.274 354.407 243.257C361.421 231.703 414.3 227.28 419.091 252.121Z"
        fill="#242F62"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M419.091 252.121C423.883 276.962 423.883 395.428 423.883 395.428C423.883 395.428 422.836 400.352 408.79 400.352C394.743 400.352 393.696 395.428 393.696 395.428L387.468 292.503L364.948 395.428C364.948 395.428 361.533 400.365 349.107 399.051C336.682 397.737 334.762 390.902 334.762 390.902C334.762 390.902 347.134 255.274 354.407 243.257C361.421 231.703 414.3 227.28 419.091 252.121Z"
        fill="url(#paint5_linear_678_17906)"
      />
      <mask
        id="mask0_678_17906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="334"
        y="233"
        width="90"
        height="168"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M419.091 252.121C423.883 276.962 423.883 395.428 423.883 395.428C423.883 395.428 422.836 400.352 408.79 400.352C394.743 400.352 393.696 395.428 393.696 395.428L387.468 292.503L364.948 395.428C364.948 395.428 361.533 400.365 349.107 399.051C336.682 397.737 334.762 390.902 334.762 390.902C334.762 390.902 347.134 255.274 354.407 243.257C361.421 231.703 414.3 227.28 419.091 252.121Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M419.091 252.121C423.883 276.962 423.883 395.428 423.883 395.428C423.883 395.428 422.836 400.352 408.79 400.352C394.743 400.352 393.696 395.428 393.696 395.428L387.468 292.503L364.948 395.428C364.948 395.428 361.533 400.365 349.107 399.051C336.682 397.737 334.762 390.902 334.762 390.902C334.762 390.902 347.134 255.274 354.407 243.257C361.421 231.703 414.3 227.28 419.091 252.121Z"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask0_678_17906)">
        <path
          d="M386.342 302.861C386.299 303.108 386.053 303.274 385.791 303.234C385.53 303.193 385.354 302.96 385.397 302.714L389.979 276.456C390.022 276.21 390.269 276.043 390.53 276.083C390.791 276.124 390.968 276.357 390.925 276.603L386.342 302.861Z"
          fill="white"
        />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M384.593 152.534H408.55V172.446H384.593V152.534Z" fill="#FFC62C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M384.593 152.534H408.55V172.446H384.593V152.534Z"
        fill="url(#paint6_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M305.534 159.775C304.478 155.288 303.724 152.139 303.138 151.177C300.918 148.207 294.398 141.897 297.868 137.601C301.819 133.527 307.544 134.09 307.451 131.718C307.544 129.44 301.693 119.596 304.097 118.142C306.585 116.963 313.218 133.879 315.596 134.886C317.804 135.902 321.031 134.424 320.867 137.601C321.031 141 315.85 147.628 316.554 151.177C316.775 152.208 317.36 154.073 317.992 156.608C313.839 154.965 307.932 156.315 305.534 159.775Z"
        fill="#FFC62C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M305.534 159.775C304.478 155.288 303.724 152.139 303.138 151.177C300.918 148.207 294.398 141.897 297.868 137.601C301.819 133.527 307.544 134.09 307.451 131.718C307.544 129.44 301.693 119.596 304.097 118.142C306.585 116.963 313.218 133.879 315.596 134.886C317.804 135.902 321.031 134.424 320.867 137.601C321.031 141 315.85 147.628 316.554 151.177C316.775 152.208 317.36 154.073 317.992 156.608C313.839 154.965 307.932 156.315 305.534 159.775Z"
        fill="url(#paint7_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.486 171.406C368.557 169.631 374.925 167.832 382.197 166.945V162.451C382.197 161.701 382.841 161.093 383.635 161.093C383.739 161.093 383.842 161.104 383.943 161.125C386.874 161.733 391.083 162.038 396.572 162.038C402.06 162.038 406.269 161.733 409.2 161.125C409.975 160.964 410.742 161.427 410.913 162.159C410.935 162.255 410.946 162.353 410.946 162.451V169.715C416.831 171.998 422.18 175.614 426.279 181.044C435.439 193.181 428.827 237.505 419.571 250.283C409.876 263.487 363.394 263.487 352.011 250.283C346.344 244.061 347.887 225.846 351.246 208.649C341.539 211.207 330.28 212.888 323.263 211.365C312.746 209.328 304.211 170.302 304.211 162.688C304.211 155.074 317.595 152.478 321.891 158.655C326.187 164.832 332.21 182.379 332.21 184.702C332.21 187.025 352.977 175.96 364.195 171.515C364.293 171.476 364.39 171.44 364.486 171.406H364.486Z"
        fill="#5776F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.486 171.406C368.557 169.631 374.925 167.832 382.197 166.945V162.451C382.197 161.701 382.841 161.093 383.635 161.093C383.739 161.093 383.842 161.104 383.943 161.125C386.874 161.733 391.083 162.038 396.572 162.038C402.06 162.038 406.269 161.733 409.2 161.125C409.975 160.964 410.742 161.427 410.913 162.159C410.935 162.255 410.946 162.353 410.946 162.451V169.715C416.831 171.998 422.18 175.614 426.279 181.044C435.439 193.181 428.827 237.505 419.571 250.283C409.876 263.487 363.394 263.487 352.011 250.283C346.344 244.061 347.887 225.846 351.246 208.649C341.539 211.207 330.28 212.888 323.263 211.365C312.746 209.328 304.211 170.302 304.211 162.688C304.211 155.074 317.595 152.478 321.891 158.655C326.187 164.832 332.21 182.379 332.21 184.702C332.21 187.025 352.977 175.96 364.195 171.515C364.293 171.476 364.39 171.44 364.486 171.406H364.486Z"
        fill="url(#paint8_linear_678_17906)"
      />
      <mask
        id="mask1_678_17906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="304"
        y="155"
        width="127"
        height="106"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M364.486 171.406C368.557 169.631 374.925 167.832 382.197 166.945V162.451C382.197 161.701 382.841 161.093 383.635 161.093C383.739 161.093 383.842 161.104 383.943 161.125C386.874 161.733 391.083 162.038 396.572 162.038C402.06 162.038 406.269 161.733 409.2 161.125C409.975 160.964 410.742 161.427 410.913 162.159C410.935 162.255 410.946 162.353 410.946 162.451V169.715C416.831 171.998 422.18 175.614 426.279 181.044C435.439 193.181 428.827 237.505 419.571 250.283C409.876 263.487 363.394 263.487 352.011 250.283C346.344 244.061 347.887 225.846 351.246 208.649C341.539 211.207 330.28 212.888 323.263 211.365C312.746 209.328 304.211 170.302 304.211 162.688C304.211 155.074 317.595 152.478 321.891 158.655C326.187 164.832 332.21 182.379 332.21 184.702C332.21 187.025 352.977 175.96 364.195 171.515C364.293 171.476 364.39 171.44 364.486 171.406H364.486Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M364.486 171.406C368.557 169.631 374.925 167.832 382.197 166.945V162.451C382.197 161.701 382.841 161.093 383.635 161.093C383.739 161.093 383.842 161.104 383.943 161.125C386.874 161.733 391.083 162.038 396.572 162.038C402.06 162.038 406.269 161.733 409.2 161.125C409.975 160.964 410.742 161.427 410.913 162.159C410.935 162.255 410.946 162.353 410.946 162.451V169.715C416.831 171.998 422.18 175.614 426.279 181.044C435.439 193.181 428.827 237.505 419.571 250.283C409.876 263.487 363.394 263.487 352.011 250.283C346.344 244.061 347.887 225.846 351.246 208.649C341.539 211.207 330.28 212.888 323.263 211.365C312.746 209.328 304.211 170.302 304.211 162.688C304.211 155.074 317.595 152.478 321.891 158.655C326.187 164.832 332.21 182.379 332.21 184.702C332.21 187.025 352.977 175.96 364.195 171.515C364.293 171.476 364.39 171.44 364.486 171.406H364.486Z"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask1_678_17906)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M351.99 250.274C344.646 243.08 351.043 209.561 351.043 209.561C351.043 209.561 356.083 207.95 363.564 204.436C365.003 203.76 365.757 263.761 351.99 250.274Z"
          fill="url(#paint9_linear_678_17906)"
        />
        <path
          d="M345.28 211.012C345.023 211.071 344.764 210.922 344.701 210.679C344.639 210.436 344.796 210.191 345.054 210.132C352.374 208.45 360.591 206.012 363.115 204.463C363.337 204.327 363.634 204.386 363.778 204.596C363.922 204.805 363.859 205.086 363.637 205.222C360.984 206.851 352.723 209.302 345.28 211.012Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M413.005 85.8549C428.612 90.3615 437.607 108.374 432.859 123.044C428.473 134.533 423.428 143.072 417.723 148.662C409.166 157.046 395.804 161.815 383.024 158.154C370.244 154.493 361.691 140.828 365.682 130.605C369.674 120.383 371.395 119.029 372.872 112.293C373.363 110.058 371.665 105.251 376.628 96.3071C381.591 87.3631 397.398 81.3482 413.005 85.8549Z"
        fill="#FFC62C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M413.005 85.8549C428.612 90.3615 437.607 108.374 432.859 123.044C428.473 134.533 423.428 143.072 417.723 148.662C409.166 157.046 395.804 161.815 383.024 158.154C370.244 154.493 361.691 140.828 365.682 130.605C369.674 120.383 371.395 119.029 372.872 112.293C373.363 110.058 371.665 105.251 376.628 96.3071C381.591 87.3631 397.398 81.3482 413.005 85.8549Z"
        fill="url(#paint10_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M396.572 127.19C387.412 127.36 381.248 122.333 376.448 115.424C375.161 113.724 373.91 110.389 373.097 106.586C373.25 105.19 373.567 103.559 374.211 101.664C374.881 103.67 375.658 105.329 376.448 106.373C381.248 113.282 387.412 118.31 396.572 118.139C403.425 118.279 404.091 124.784 401.536 129.212C400.52 128.022 398.904 127.238 396.572 127.19Z"
        fill="url(#paint11_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M384.593 79.6747C367.311 70.764 371.633 100.011 376.448 106.375C381.248 113.284 387.412 118.311 396.572 118.141C404.914 118.311 404.088 127.91 399.447 131.717C394.415 135.731 388.964 144.769 393.218 151.176C397.471 157.583 406.421 159.954 420.529 151.176C434.723 142.773 449.47 115.573 432.508 97.3238C415.92 79.4691 401.57 88.1742 384.593 79.6747Z"
        fill="#222E5F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M384.593 79.6747C367.311 70.764 371.633 100.011 376.448 106.375C381.248 113.284 387.412 118.311 396.572 118.141C404.914 118.311 404.088 127.91 399.447 131.717C394.415 135.731 388.964 144.769 393.218 151.176C397.471 157.583 406.421 159.954 420.529 151.176C434.723 142.773 449.47 115.573 432.508 97.3238C415.92 79.4691 401.57 88.1742 384.593 79.6747Z"
        fill="url(#paint12_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.1227 381.9C95.1521 382.75 95.1344 383.543 95.059 384.196C94.5876 388.283 92.4315 391.008 89.0246 395.317C85.6177 399.625 77.4914 408.618 69.2287 403.504C61.4865 398.713 73.894 384.433 79.1953 381.461C82.8416 383.895 91.1272 384.549 95.1227 381.9Z"
        fill="#5C7AF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.1227 381.9C95.1521 382.75 95.1344 383.543 95.059 384.196C94.5876 388.283 92.4315 391.008 89.0246 395.317C85.6177 399.625 77.4914 408.618 69.2287 403.504C61.4865 398.713 73.894 384.433 79.1953 381.461C82.8416 383.895 91.1272 384.549 95.1227 381.9Z"
        fill="url(#paint13_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.437 380.555C141.16 382.765 157.359 392.059 153.593 399.035C149.433 406.738 132.992 397.978 129.178 395.458C125.364 392.939 122.121 389.895 120.482 386.995C119.901 385.966 119.586 383.606 119.485 381.206C123.296 383.056 131.422 383.896 135.437 380.555Z"
        fill="#5B78EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.437 380.555C141.16 382.765 157.359 392.059 153.593 399.035C149.433 406.738 132.992 397.978 129.178 395.458C125.364 392.939 122.121 389.895 120.482 386.995C119.901 385.966 119.586 383.606 119.485 381.206C123.296 383.056 131.422 383.896 135.437 380.555Z"
        fill="url(#paint14_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.591 241.49C144.441 260.924 138.665 364.713 138.056 375.555C137.98 386.16 120.139 383.479 117.921 380.085C115.641 376.207 108.688 281.363 106.416 281.347C103.977 281.363 99.1329 374.115 96.8283 380.085C94.0866 385.571 79.9537 384.249 77.6529 380.085C74.8999 375.437 61.9028 271.011 82.4467 241.49C84.534 238.49 122.683 238.49 125.591 241.49Z"
        fill="#232F61"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.591 241.49C144.441 260.924 138.665 364.713 138.056 375.555C137.98 386.16 120.139 383.479 117.921 380.085C115.641 376.207 108.688 281.363 106.416 281.347C103.977 281.363 99.1329 374.115 96.8283 380.085C94.0866 385.571 79.9537 384.249 77.6529 380.085C74.8999 375.437 61.9028 271.011 82.4467 241.49C84.534 238.49 122.683 238.49 125.591 241.49Z"
        fill="url(#paint15_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.3969 97.9287C71.2412 77.1246 103.796 71.1107 120.923 87.0585C138.202 103.275 146.276 92.9836 155.439 100.193C164.665 107.623 155.47 121.201 146.331 128.728C137.277 136.366 158.184 150.369 152.563 168.585C147.012 186.762 121.133 179.813 112.774 190.778C104.262 201.873 88.8813 181.017 62.4381 178.549C36.2967 175.88 32.0541 146.567 51.4123 132.804C71.1048 118.961 55.8541 118.961 63.3969 97.9287Z"
        fill="#232F62"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.3969 97.9287C71.2412 77.1246 103.796 71.1107 120.923 87.0585C138.202 103.275 146.276 92.9836 155.439 100.193C164.665 107.623 155.47 121.201 146.331 128.728C137.277 136.366 158.184 150.369 152.563 168.585C147.012 186.762 121.133 179.813 112.774 190.778C104.262 201.873 88.8813 181.017 62.4381 178.549C36.2967 175.88 32.0541 146.567 51.4123 132.804C71.1048 118.961 55.8541 118.961 63.3969 97.9287Z"
        fill="url(#paint16_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.863 182.115C128.144 183.761 121.039 184.628 116.183 187.712C114.852 188.546 113.697 189.548 112.773 190.779C110.686 193.477 107.955 194.239 104.67 193.838C96.7499 192.484 85.5647 183.391 70.013 179.784C54.0939 162.896 66.8269 125.684 75.8607 140.957C82.8757 152.683 100.287 148.152 128.114 127.369C140.861 146.867 144.555 162.463 139.14 174.02C137.604 177.345 136.254 179.947 134.863 182.115Z"
        fill="url(#paint17_linear_678_17906)"
      />
      <mask
        id="mask2_678_17906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="62"
        y="127"
        width="80"
        height="67"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.863 182.115C128.144 183.761 121.039 184.628 116.183 187.712C114.852 188.546 113.697 189.548 112.773 190.779C110.686 193.477 107.955 194.239 104.67 193.838C96.7499 192.484 85.5647 183.391 70.013 179.784C54.0939 162.896 66.8269 125.684 75.8607 140.957C82.8757 152.683 100.287 148.152 128.114 127.369C140.861 146.867 144.555 162.463 139.14 174.02C137.604 177.345 136.254 179.947 134.863 182.115Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_678_17906)"></g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.843 175.859C103.276 175.842 103.71 175.833 104.145 175.833C104.582 175.833 105.017 175.842 105.45 175.859C105.643 175.841 105.847 175.833 106.062 175.833C121.4 175.774 135.893 181.441 150.166 207.991C151.844 210.849 171.977 177.904 171.738 174.928C171.977 171.915 172.82 157.466 174.614 156.358C176.896 156.006 178.53 157.204 177.011 164.057C176.139 171.608 202.301 153.746 203.857 160.434C206.073 167.448 199.626 174.341 188.037 176.739C176.517 179.792 178.227 178.573 176.532 185.798C174.858 193.335 164.426 226.758 150.166 227.467C146.24 227.657 139.69 223.571 132.691 217.623L125.717 241.508C118.541 244.527 111.35 246.037 104.145 246.037C96.9501 246.037 89.7593 244.527 82.5723 241.508L75.5985 217.623C68.5997 223.571 62.0497 227.657 58.1236 227.467C43.8636 226.758 33.4318 193.335 31.7574 185.798C30.0622 178.573 31.7722 179.792 20.2521 176.739C8.66332 174.341 2.21656 167.448 4.4324 160.434C5.98804 153.746 32.1503 171.608 31.278 164.057C29.7598 157.204 31.3936 156.006 33.6749 156.358C35.4699 157.466 36.3123 171.915 36.5513 174.928C36.3123 177.904 56.4451 210.849 58.1236 207.991C72.396 181.441 86.8896 175.774 102.227 175.833C102.444 175.833 102.649 175.841 102.843 175.859Z"
        fill="#FFC62C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.843 175.859C103.276 175.842 103.71 175.833 104.145 175.833C104.582 175.833 105.017 175.842 105.45 175.859C105.643 175.841 105.847 175.833 106.062 175.833C121.4 175.774 135.893 181.441 150.166 207.991C151.844 210.849 171.977 177.904 171.738 174.928C171.977 171.915 172.82 157.466 174.614 156.358C176.896 156.006 178.53 157.204 177.011 164.057C176.139 171.608 202.301 153.746 203.857 160.434C206.073 167.448 199.626 174.341 188.037 176.739C176.517 179.792 178.227 178.573 176.532 185.798C174.858 193.335 164.426 226.758 150.166 227.467C146.24 227.657 139.69 223.571 132.691 217.623L125.717 241.508C118.541 244.527 111.35 246.037 104.145 246.037C96.9501 246.037 89.7593 244.527 82.5723 241.508L75.5985 217.623C68.5997 223.571 62.0497 227.657 58.1236 227.467C43.8636 226.758 33.4318 193.335 31.7574 185.798C30.0622 178.573 31.7722 179.792 20.2521 176.739C8.66332 174.341 2.21656 167.448 4.4324 160.434C5.98804 153.746 32.1503 171.608 31.278 164.057C29.7598 157.204 31.3936 156.006 33.6749 156.358C35.4699 157.466 36.3123 171.915 36.5513 174.928C36.3123 177.904 56.4451 210.849 58.1236 207.991C72.396 181.441 86.8896 175.774 102.227 175.833C102.444 175.833 102.649 175.841 102.843 175.859Z"
        fill="url(#paint18_linear_678_17906)"
      />
      <mask
        id="mask3_678_17906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="156"
        width="202"
        height="91"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.843 175.859C103.276 175.842 103.71 175.833 104.145 175.833C104.582 175.833 105.017 175.842 105.45 175.859C105.643 175.841 105.847 175.833 106.062 175.833C121.4 175.774 135.893 181.441 150.166 207.991C151.844 210.849 171.977 177.904 171.738 174.928C171.977 171.915 172.82 157.466 174.614 156.358C176.896 156.006 178.53 157.204 177.011 164.057C176.139 171.608 202.301 153.746 203.857 160.434C206.073 167.448 199.626 174.341 188.037 176.739C176.517 179.792 178.227 178.573 176.532 185.798C174.858 193.335 164.426 226.758 150.166 227.467C146.24 227.657 139.69 223.571 132.691 217.623L125.717 241.508C118.541 244.527 111.35 246.037 104.145 246.037C96.9501 246.037 89.7593 244.527 82.5723 241.508L75.5985 217.623C68.5997 223.571 62.0497 227.657 58.1236 227.467C43.8636 226.758 33.4318 193.335 31.7574 185.798C30.0622 178.573 31.7722 179.792 20.2521 176.739C8.66332 174.341 2.21656 167.448 4.4324 160.434C5.98804 153.746 32.1503 171.608 31.278 164.057C29.7598 157.204 31.3936 156.006 33.6749 156.358C35.4699 157.466 36.3123 171.915 36.5513 174.928C36.3123 177.904 56.4451 210.849 58.1236 207.991C72.396 181.441 86.8896 175.774 102.227 175.833C102.444 175.833 102.649 175.841 102.843 175.859Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.843 175.859C103.276 175.842 103.71 175.833 104.145 175.833C104.582 175.833 105.017 175.842 105.45 175.859C105.643 175.841 105.847 175.833 106.062 175.833C121.4 175.774 135.893 181.441 150.166 207.991C151.844 210.849 171.977 177.904 171.738 174.928C171.977 171.915 172.82 157.466 174.614 156.358C176.896 156.006 178.53 157.204 177.011 164.057C176.139 171.608 202.301 153.746 203.857 160.434C206.073 167.448 199.626 174.341 188.037 176.739C176.517 179.792 178.227 178.573 176.532 185.798C174.858 193.335 164.426 226.758 150.166 227.467C146.24 227.657 139.69 223.571 132.691 217.623L125.717 241.508C118.541 244.527 111.35 246.037 104.145 246.037C96.9501 246.037 89.7593 244.527 82.5723 241.508L75.5985 217.623C68.5997 223.571 62.0497 227.657 58.1236 227.467C43.8636 226.758 33.4318 193.335 31.7574 185.798C30.0622 178.573 31.7722 179.792 20.2521 176.739C8.66332 174.341 2.21656 167.448 4.4324 160.434C5.98804 153.746 32.1503 171.608 31.278 164.057C29.7598 157.204 31.3936 156.006 33.6749 156.358C35.4699 157.466 36.3123 171.915 36.5513 174.928C36.3123 177.904 56.4451 210.849 58.1236 207.991C72.396 181.441 86.8896 175.774 102.227 175.833C102.444 175.833 102.649 175.841 102.843 175.859Z"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask3_678_17906)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.352 221.348C135.838 220.219 134.277 218.967 132.692 217.619L125.717 241.507C118.509 244.526 111.318 246.036 104.145 246.036C96.9767 246.036 89.7859 244.526 82.5722 241.507L75.5974 217.619C74.4636 218.583 73.3415 219.499 72.2404 220.355C67.7701 219.173 64.8876 216.661 63.5801 215.39C62.2725 214.119 59.813 212.363 58.1235 207.99C72.4102 181.392 86.8918 175.719 102.227 175.833C102.449 175.831 102.66 175.84 102.859 175.858C103.287 175.841 103.716 175.833 104.145 175.833C104.577 175.833 105.006 175.841 105.434 175.858C105.632 175.84 105.841 175.831 106.062 175.833C121.397 175.719 135.879 181.392 150.166 207.99C150.166 207.99 149.93 210.934 145.977 215.39C142.024 219.846 137.352 221.348 137.352 221.348Z"
          fill="#597AF9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.352 221.348C135.838 220.219 134.277 218.967 132.692 217.619L125.717 241.507C118.509 244.526 111.318 246.036 104.145 246.036C96.9767 246.036 89.7859 244.526 82.5722 241.507L75.5974 217.619C74.4636 218.583 73.3415 219.499 72.2404 220.355C67.7701 219.173 64.8876 216.661 63.5801 215.39C62.2725 214.119 59.813 212.363 58.1235 207.99C72.4102 181.392 86.8918 175.719 102.227 175.833C102.449 175.831 102.66 175.84 102.859 175.858C103.287 175.841 103.716 175.833 104.145 175.833C104.577 175.833 105.006 175.841 105.434 175.858C105.632 175.84 105.841 175.831 106.062 175.833C121.397 175.719 135.879 181.392 150.166 207.99C150.166 207.99 149.93 210.934 145.977 215.39C142.024 219.846 137.352 221.348 137.352 221.348Z"
          fill="url(#paint19_linear_678_17906)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.335 189.422C116.101 189.422 119.964 184.961 119.964 179.458C119.964 173.954 116.101 169.493 111.335 169.493C106.569 169.493 102.706 173.954 102.706 179.458C102.706 184.961 106.569 189.422 111.335 189.422Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.9537 189.422C101.719 189.422 105.583 184.961 105.583 179.458C105.583 173.954 101.719 169.493 96.9537 169.493C92.188 169.493 88.3247 173.954 88.3247 179.458C88.3247 184.961 92.188 189.422 96.9537 189.422Z"
          fill="white"
        />
        <path
          d="M76.9476 222.258C77.0167 222.499 76.8656 222.748 76.61 222.813C76.3545 222.878 76.0912 222.736 76.0221 222.494C76.0105 222.454 76.0105 222.454 75.999 222.414C75.8392 221.858 75.049 219.237 74.8578 218.55C74.4748 217.173 74.2476 216.097 74.1428 215.049C73.7388 211.01 75.2917 207.268 80.0035 201.693C80.1687 201.497 80.4704 201.465 80.6772 201.621C80.8841 201.778 80.9179 202.063 80.7527 202.258C76.1827 207.665 74.7199 211.19 75.0974 214.964C75.1965 215.955 75.4142 216.986 75.7851 218.32C75.9732 218.996 76.762 221.612 76.9242 222.176C76.9359 222.217 76.9359 222.217 76.9476 222.258Z"
          fill="white"
        />
        <path
          d="M132.214 222.819C132.145 223.061 131.882 223.203 131.626 223.138C131.371 223.073 131.22 222.824 131.289 222.583C131.388 222.237 132.21 219.469 132.413 218.722C132.777 217.383 132.995 216.329 133.097 215.317C133.494 211.372 132.013 207.72 127.397 202.258C127.231 202.063 127.265 201.778 127.472 201.621C127.679 201.465 127.981 201.497 128.146 201.693C132.904 207.323 134.475 211.195 134.051 215.403C133.944 216.469 133.717 217.566 133.341 218.947C133.135 219.706 132.311 222.482 132.214 222.819Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.145 163.152C108.645 163.152 112.294 167.07 112.294 171.902V177.049C112.294 181.881 108.645 185.799 104.145 185.799C99.6437 185.799 95.995 181.881 95.995 177.049V171.902C95.995 167.07 99.6437 163.152 104.145 163.152Z"
        fill="#FFC62C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.145 163.152C108.645 163.152 112.294 167.07 112.294 171.902V177.049C112.294 181.881 108.645 185.799 104.145 185.799C99.6437 185.799 95.995 181.881 95.995 177.049V171.902C95.995 167.07 99.6437 163.152 104.145 163.152Z"
        fill="url(#paint20_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.294 176.867C111.192 177.08 110.071 177.193 108.939 177.193C105.154 177.193 100.567 176.233 95.9951 174.425L95.9951 171.304C95.9951 167.051 99.6438 163.604 104.145 163.604C108.646 163.604 112.294 167.051 112.294 171.304V176.867Z"
        fill="url(#paint21_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.1082 145.032C65.6837 137.978 72.3798 133.702 74.4227 136.879C75.4423 138.248 75.7732 139.55 75.8608 140.956C79.7819 147.247 94.6302 133.831 99.3508 128.727C104.503 123.344 105.242 117.675 108.938 128.727C111.544 136.041 120.913 133.147 128.114 127.368C128.191 129.509 128.114 131.796 128.114 134.162C128.114 136.298 130.99 139.123 130.99 149.108C130.99 159.001 119.94 172.66 107.5 172.66C98.0227 172.66 83.51 166.639 76.8196 156.355C71.2454 156.349 74.728 152.065 70.1082 145.032Z"
        fill="#FFC62C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.1082 145.032C65.6837 137.978 72.3798 133.702 74.4227 136.879C75.4423 138.248 75.7732 139.55 75.8608 140.956C79.7819 147.247 94.6302 133.831 99.3508 128.727C104.503 123.344 105.242 117.675 108.938 128.727C111.544 136.041 120.913 133.147 128.114 127.368C128.191 129.509 128.114 131.796 128.114 134.162C128.114 136.298 130.99 139.123 130.99 149.108C130.99 159.001 119.94 172.66 107.5 172.66C98.0227 172.66 83.51 166.639 76.8196 156.355C71.2454 156.349 74.728 152.065 70.1082 145.032Z"
        fill="url(#paint22_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.938 137.786C105.24 126.736 104.502 132.426 99.3508 137.786C94.643 142.95 82.3853 151.318 75.8608 140.957C79.8153 147.323 94.643 133.859 99.3508 128.728C104.502 123.335 105.24 117.646 108.938 128.728C111.533 136.077 120.889 133.173 128.114 127.369C128.158 129.522 128.08 131.817 128.114 134.163C128.08 136.335 111.533 145.168 108.938 137.786Z"
        fill="url(#paint23_linear_678_17906)"
      />
      <path
        d="M75.0588 153.485C75.3229 153.485 75.5371 153.688 75.5371 153.938C75.5371 154.188 75.3229 154.391 75.0588 154.391C74.8929 154.391 74.5849 154.778 74.331 155.534C74.0351 156.414 73.8632 157.623 73.8632 158.918C73.8632 160.212 74.0351 161.421 74.331 162.301C74.5849 163.057 74.8929 163.444 75.0588 163.444C75.2247 163.444 75.5327 163.057 75.7867 162.301C76.0825 161.421 76.2544 160.212 76.2544 158.918C76.2544 158.525 76.2387 158.139 76.208 157.766C76.1875 157.517 76.3844 157.299 76.6477 157.279C76.9111 157.26 77.1411 157.446 77.1616 157.696C77.1943 158.093 77.2109 158.502 77.2109 158.918C77.2109 161.923 76.3952 164.35 75.0588 164.35C73.7225 164.35 72.9067 161.923 72.9067 158.918C72.9067 155.912 73.7225 153.485 75.0588 153.485Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.815 199.652C164.854 167.03 193.09 145.741 206.422 139.454C240.06 122.765 264.903 144.155 268.115 146.243C271.769 147.839 316.702 135.548 336.982 182.905C357.325 230.207 329.936 259.456 329.808 282.029C329.936 304.181 375.991 349.49 329.808 385.679C283.881 421.844 180.159 412.993 161.468 364.858C142.883 317.007 179.364 293.445 180.119 268.45C180.86 245.63 164.854 238.554 164.815 199.652Z"
        fill="#232D5F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.815 199.652C164.854 167.03 193.09 145.741 206.422 139.454C240.06 122.765 264.903 144.155 268.115 146.243C271.769 147.839 316.702 135.548 336.982 182.905C357.325 230.207 329.936 259.456 329.808 282.029C329.936 304.181 375.991 349.49 329.808 385.679C283.881 421.844 180.159 412.993 161.468 364.858C142.883 317.007 179.364 293.445 180.119 268.45C180.86 245.63 164.854 238.554 164.815 199.652Z"
        fill="url(#paint24_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M338.115 378.047C335.757 380.627 333.003 383.174 329.809 385.678C287.026 419.367 194.092 413.993 166.457 374.19C197.134 322.431 212.472 285.957 212.472 264.767C212.472 227.536 294.215 210.133 298.245 228.166C302.274 246.199 287.421 279.972 314.206 300.149C336.063 316.614 350.748 350.034 338.115 378.047Z"
        fill="url(#paint25_linear_678_17906)"
      />
      <mask
        id="mask4_678_17906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="166"
        y="221"
        width="177"
        height="187"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M338.115 378.047C335.757 380.627 333.003 383.174 329.809 385.678C287.026 419.367 194.092 413.993 166.457 374.19C197.134 322.431 212.472 285.957 212.472 264.767C212.472 227.536 294.215 210.133 298.245 228.166C302.274 246.199 287.421 279.972 314.206 300.149C336.063 316.614 350.748 350.034 338.115 378.047Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_678_17906)"></g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.593 301.49C313.784 304.626 303.508 412.928 290.593 421.887C277.678 430.846 232.797 433.583 193.51 421.887C154.223 410.191 170.766 297.467 193.51 296.059C216.089 294.529 266.999 298.233 290.593 301.49Z"
        fill="#5979F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.593 301.49C313.784 304.626 303.508 412.928 290.593 421.887C277.678 430.846 232.797 433.583 193.51 421.887C154.223 410.191 170.766 297.467 193.51 296.059C216.089 294.529 266.999 298.233 290.593 301.49Z"
        fill="url(#paint26_linear_678_17906)"
      />
      <mask
        id="mask5_678_17906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="169"
        y="295"
        width="136"
        height="135"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M290.593 301.49C313.784 304.626 303.508 412.928 290.593 421.887C277.678 430.846 232.797 433.583 193.51 421.887C154.223 410.191 170.766 297.467 193.51 296.059C216.089 294.529 266.999 298.233 290.593 301.49Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M290.593 301.49C313.784 304.626 303.508 412.928 290.593 421.887C277.678 430.846 232.797 433.583 193.51 421.887C154.223 410.191 170.766 297.467 193.51 296.059C216.089 294.529 266.999 298.233 290.593 301.49Z"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask5_678_17906)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.119 369.386C180.119 369.386 197.614 374.817 209.77 387.943C222.029 401.069 253.264 379.957 277.202 394.732C301.435 409.508 278.159 428.226 278.159 428.226L173.902 432.752L180.119 369.386Z"
          fill="url(#paint27_linear_678_17906)"
        />
      </g>
      <path
        d="M223.639 279.765H260.464V300.278C260.464 310.446 252.22 318.69 242.051 318.69C231.883 318.69 223.639 310.446 223.639 300.278V279.765Z"
        fill="#FFC62C"
      />
      <path
        d="M223.639 279.765H260.464V300.278C260.464 310.446 252.22 318.69 242.051 318.69C231.883 318.69 223.639 310.446 223.639 300.278V279.765Z"
        fill="url(#paint28_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.813 298.775C228.256 298.185 225.868 297.444 223.639 296.574L223.639 279.765H260.464V297.702C252.065 300.835 242.407 301.576 230.813 298.775Z"
        fill="url(#paint29_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.593 301.491C315.381 304.973 313.995 334.111 314.027 367.12C313.995 400.489 307.187 443.613 286.767 443.613C266.41 443.613 195.312 443.613 193.988 421.887C193.345 406.818 195.312 399.816 204.509 401.519C218.473 404.542 273.376 406.951 273.376 406.951C273.376 406.951 286.758 365.337 286.767 352.184C286.924 310.952 278.122 300.085 290.593 301.491Z"
        fill="#5674F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.593 301.491C315.381 304.973 313.995 334.111 314.027 367.12C313.995 400.489 307.187 443.613 286.767 443.613C266.41 443.613 195.312 443.613 193.988 421.887C193.345 406.818 195.312 399.816 204.509 401.519C218.473 404.542 273.376 406.951 273.376 406.951C273.376 406.951 286.758 365.337 286.767 352.184C286.924 310.952 278.122 300.085 290.593 301.491Z"
        fill="url(#paint30_linear_678_17906)"
      />
      <mask
        id="mask6_678_17906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="193"
        y="301"
        width="122"
        height="143"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M290.593 301.491C315.381 304.973 313.995 334.111 314.027 367.12C313.995 400.489 307.187 443.613 286.767 443.613C266.41 443.613 195.312 443.613 193.988 421.887C193.345 406.818 195.312 399.816 204.509 401.519C218.473 404.542 273.376 406.951 273.376 406.951C273.376 406.951 286.758 365.337 286.767 352.184C286.924 310.952 278.122 300.085 290.593 301.491Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M290.593 301.491C315.381 304.973 313.995 334.111 314.027 367.12C313.995 400.489 307.187 443.613 286.767 443.613C266.41 443.613 195.312 443.613 193.988 421.887C193.345 406.818 195.312 399.816 204.509 401.519C218.473 404.542 273.376 406.951 273.376 406.951C273.376 406.951 286.758 365.337 286.767 352.184C286.924 310.952 278.122 300.085 290.593 301.491Z"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask6_678_17906)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M214.553 437.274L258.073 417.812L224.683 445.408L214.553 437.274Z"
          fill="url(#paint31_linear_678_17906)"
        />
      </g>
      <path
        d="M273.584 406.245C273.469 406.604 273.068 406.806 272.689 406.697C272.31 406.588 272.096 406.208 272.212 405.85C274.514 398.692 276.518 392.167 278.226 386.274C283.119 369.386 285.567 357.705 285.571 351.28C285.572 350.905 285.893 350.601 286.289 350.602C286.685 350.602 287.006 350.906 287.006 351.281C287.002 357.864 284.536 369.633 279.609 386.634C277.898 392.539 275.89 399.076 273.584 406.245Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.942 396.542C258.819 396.542 274.811 402.426 274.811 402.426L273.376 406.952C273.376 406.952 260.387 417.281 258.072 417.815C252.912 418.854 241.066 396.542 249.942 396.542Z"
        fill="#FFC62C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.942 396.542C258.819 396.542 274.811 402.426 274.811 402.426L273.376 406.952C273.376 406.952 260.387 417.281 258.072 417.815C252.912 418.854 241.066 396.542 249.942 396.542Z"
        fill="url(#paint32_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.466 296.058C180.467 296.881 164.989 312.793 157.164 351.278C149.279 390.097 138.066 435.132 157.164 440.444C165.66 442.868 196.418 442.868 214.553 437.276C239.297 429.646 257.656 417.813 258.072 417.813C258.82 417.813 254.927 396.497 249.942 396.54C244.958 396.497 185.858 403.782 185.858 403.782C185.858 403.782 185.666 400.78 176.293 363.951C166.984 327.237 208.466 295.236 194.466 296.058Z"
        fill="#5674EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.466 296.058C180.467 296.881 164.989 312.793 157.164 351.278C149.279 390.097 138.066 435.132 157.164 440.444C165.66 442.868 196.418 442.868 214.553 437.276C239.297 429.646 257.656 417.813 258.072 417.813C258.82 417.813 254.927 396.497 249.942 396.54C244.958 396.497 185.858 403.782 185.858 403.782C185.858 403.782 185.666 400.78 176.293 363.951C166.984 327.237 208.466 295.236 194.466 296.058Z"
        fill="url(#paint33_linear_678_17906)"
      />
      <path
        d="M179.164 372.368C179.256 372.733 179.018 373.099 178.633 373.186C178.247 373.273 177.86 373.048 177.768 372.684C177.091 369.998 176.367 367.141 175.596 364.112C172.442 351.674 174.693 339.07 181.071 325.67C181.234 325.328 181.659 325.176 182.02 325.33C182.381 325.484 182.542 325.886 182.379 326.227C176.121 339.377 173.921 351.69 176.991 363.795C177.762 366.824 178.486 369.682 179.164 372.368Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.51 401.518C204.51 401.518 187.149 372.354 180.119 369.382C172.703 366.642 156.722 363.24 152.381 365.761C148.303 368.173 144.168 380.754 146.164 386.129C148.303 391.316 159.138 400.406 166.729 401.518C170.068 402.008 185.858 403.781 185.858 403.781L204.51 401.518Z"
        fill="#FFC62C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.51 401.518C204.51 401.518 187.149 372.354 180.119 369.382C172.703 366.642 156.722 363.24 152.381 365.761C148.303 368.173 144.168 380.754 146.164 386.129C148.303 391.316 159.138 400.406 166.729 401.518C170.068 402.008 185.858 403.781 185.858 403.781L204.51 401.518Z"
        fill="url(#paint34_linear_678_17906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M298.245 228.167C304.402 226.83 315.689 231.354 310.201 245.819C306.097 256.418 297.564 265.732 287.723 263.019C273.355 279.76 257.004 291.616 230.813 285.197C204.242 279.184 195.8 256.908 195.423 244.461C192.862 231.547 205.01 188.62 207.379 184.263C213.206 174.57 233.821 197.141 258.551 208.252C283.074 219.657 305.369 209.955 298.245 228.167Z"
        fill="#FFC62C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M298.245 228.167C304.402 226.83 315.689 231.354 310.201 245.819C306.097 256.418 297.564 265.732 287.723 263.019C273.355 279.76 257.004 291.616 230.813 285.197C204.242 279.184 195.8 256.908 195.423 244.461C192.862 231.547 205.01 188.62 207.379 184.263C213.206 174.57 233.821 197.141 258.551 208.252C283.074 219.657 305.369 209.955 298.245 228.167Z"
        fill="url(#paint35_linear_678_17906)"
      />
      <mask
        id="mask7_678_17906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="195"
        y="181"
        width="117"
        height="106"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M298.245 228.167C304.402 226.83 315.689 231.354 310.201 245.819C306.097 256.418 297.564 265.732 287.723 263.019C273.355 279.76 257.004 291.616 230.813 285.197C204.242 279.184 195.8 256.908 195.423 244.461C192.862 231.547 205.01 188.62 207.379 184.263C213.206 174.57 233.821 197.141 258.551 208.252C283.074 219.657 305.369 209.955 298.245 228.167Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M298.245 228.167C304.402 226.83 315.689 231.354 310.201 245.819C306.097 256.418 297.564 265.732 287.723 263.019C273.355 279.76 257.004 291.616 230.813 285.197C204.242 279.184 195.8 256.908 195.423 244.461C192.862 231.547 205.01 188.62 207.379 184.263C213.206 174.57 233.821 197.141 258.551 208.252C283.074 219.657 305.369 209.955 298.245 228.167Z"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask7_678_17906)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M206.422 186.529C206.422 186.529 213.972 186.797 240.377 215.044C266.783 243.29 298.245 228.169 298.245 228.169L313.07 205.539L210.248 166.613L206.422 186.529Z"
          fill="url(#paint36_linear_678_17906)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_678_17906"
          x1="425.32"
          y1="261.146"
          x2="425.32"
          y2="289.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC62C" stopOpacity="0.01" />
          <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_678_17906"
          x1="417.175"
          y1="181.042"
          x2="417.175"
          y2="267.121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A7EE" stopOpacity="0.01" />
          <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_678_17906"
          x1="419.046"
          y1="258.178"
          x2="438.727"
          y2="258.457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#464D89" stopOpacity="0.6" />
          <stop offset="1" stopColor="#A7A7EE" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_678_17906"
          x1="378.843"
          y1="389.211"
          x2="378.843"
          y2="406.936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A7EE" stopOpacity="0.01" />
          <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_678_17906"
          x1="323.262"
          y1="388.758"
          x2="323.262"
          y2="406.483"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A7EE" stopOpacity="0.01" />
          <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_678_17906"
          x1="334.762"
          y1="233.989"
          x2="334.762"
          y2="400.352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#403F9F" stopOpacity="0.01" />
          <stop offset="1" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_678_17906"
          x1="384.593"
          y1="152.534"
          x2="384.593"
          y2="172.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC62C" stopOpacity="0.01" />
          <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_678_17906"
          x1="296.91"
          y1="118.142"
          x2="296.91"
          y2="200.504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC62C" stopOpacity="0.01" />
          <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_678_17906"
          x1="304.211"
          y1="155.207"
          x2="304.211"
          y2="260.186"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A7EE" stopOpacity="0.01" />
          <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_678_17906"
          x1="356.186"
          y1="180.521"
          x2="327.061"
          y2="190.414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#464D89" stopOpacity="0.3" />
          <stop offset="1" stopColor="#A7A7EE" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_678_17906"
          x1="364.694"
          y1="84.3223"
          x2="364.694"
          y2="159.401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC62C" stopOpacity="0.01" />
          <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_678_17906"
          x1="364.899"
          y1="130.525"
          x2="402.826"
          y2="141.297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC62C" stopOpacity="0.01" />
          <stop offset="1" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_678_17906"
          x1="372.17"
          y1="78"
          x2="372.17"
          y2="156.905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#403F9F" stopOpacity="0.01" />
          <stop offset="1" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_678_17906"
          x1="66.7523"
          y1="381.461"
          x2="66.7523"
          y2="405.034"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A7EE" stopOpacity="0.01" />
          <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_678_17906"
          x1="119.485"
          y1="380.555"
          x2="119.485"
          y2="402.137"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A7EE" stopOpacity="0.01" />
          <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_678_17906"
          x1="71.0669"
          y1="239.24"
          x2="71.0669"
          y2="383.72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#403F9F" stopOpacity="0.01" />
          <stop offset="1" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_678_17906"
          x1="39.4276"
          y1="78"
          x2="39.4276"
          y2="193.949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#403F9F" stopOpacity="0.01" />
          <stop offset="1" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_678_17906"
          x1="22.8811"
          y1="160.654"
          x2="88.4698"
          y2="238.605"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.3" />
          <stop offset="1" stopColor="#1F1845" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_678_17906"
          x1="3.99963"
          y1="156.303"
          x2="3.99963"
          y2="246.037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC62C" stopOpacity="0.01" />
          <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_678_17906"
          x1="58.1235"
          y1="175.831"
          x2="58.1235"
          y2="246.036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A7EE" stopOpacity="0.01" />
          <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_678_17906"
          x1="95.995"
          y1="163.152"
          x2="95.995"
          y2="185.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC62C" stopOpacity="0.01" />
          <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_678_17906"
          x1="103.772"
          y1="156.809"
          x2="90.5104"
          y2="173.442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.35" />
          <stop offset="1" stopColor="#FFC62C" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_678_17906"
          x1="68.67"
          y1="122.386"
          x2="68.67"
          y2="172.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC62C" stopOpacity="0.01" />
          <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_678_17906"
          x1="101.987"
          y1="110.611"
          x2="84.3411"
          y2="149.761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopColor="#FFC62C" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_678_17906"
          x1="156.207"
          y1="133.117"
          x2="156.207"
          y2="407.857"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F1845" stopOpacity="0.01" />
          <stop offset="1" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_678_17906"
          x1="91.3151"
          y1="249.849"
          x2="184.512"
          y2="465.591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.646003" />
          <stop offset="1" stopColor="#1F1845" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_678_17906"
          x1="169.848"
          y1="295.703"
          x2="169.848"
          y2="429.666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#403F9F" stopOpacity="0.01" />
          <stop offset="1" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_678_17906"
          x1="116.035"
          y1="401.069"
          x2="169.42"
          y2="498.573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.155089" />
          <stop offset="1" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_678_17906"
          x1="224.937"
          y1="279.079"
          x2="223.487"
          y2="317.998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C74D75" stopOpacity="0.01" />
          <stop offset="1" stopColor="#AF3B6E" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_678_17906"
          x1="205.227"
          y1="290.14"
          x2="222.976"
          y2="321.639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopColor="#FF6663" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_678_17906"
          x1="193.877"
          y1="301.373"
          x2="193.877"
          y2="443.613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#403F9F" stopOpacity="0.01" />
          <stop offset="1" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_678_17906"
          x1="198.478"
          y1="429.807"
          x2="218.963"
          y2="466.967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.155089" />
          <stop offset="1" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_678_17906"
          x1="247.729"
          y1="396.166"
          x2="247.159"
          y2="417.486"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C74D75" stopOpacity="0.01" />
          <stop offset="1" stopColor="#AF3B6E" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_678_17906"
          x1="146.641"
          y1="296.043"
          x2="146.641"
          y2="441.986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#403F9F" stopOpacity="0.01" />
          <stop offset="1" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_678_17906"
          x1="147.721"
          y1="364.192"
          x2="146.814"
          y2="403.123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C74D75" stopOpacity="0.01" />
          <stop offset="1" stopColor="#AF3B6E" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_678_17906"
          x1="199.058"
          y1="180.149"
          x2="195.726"
          y2="285.182"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C74D75" stopOpacity="0.01" />
          <stop offset="1" stopColor="#AF3B6E" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_678_17906"
          x1="153.098"
          y1="200.027"
          x2="213.237"
          y2="295.999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopColor="#FF6663" stopOpacity="0.01" />
        </linearGradient>
        <clipPath id="clip0_678_17906">
          <rect width="110.646" height="164.964" fill="white" transform="translate(193)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Support
