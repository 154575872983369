import React from 'react'

export interface EmptyStateNoResultProps {
  readonly darkMode?: boolean
}

const EmptyStateNoResult: React.FunctionComponent<EmptyStateNoResultProps> = ({ darkMode }) => {
  if (darkMode) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="414"
        style={{ maxWidth: '415px', marginTop: '32px' }}
        height="318"
        viewBox="0 0 414 318"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M414 187.728C414 134.881 387.243 112.74 320.823 97.8202C254.361 83.1411 245.553 0 159.293 0C72.7943 0 -40.8838 148.025 16.4695 233.042C73.8023 318.144 233.051 202.053 285.207 240.595C337.466 278.953 414 240.429 414 187.728Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M256.574 304.753C252.156 286.298 212.426 275.554 185.1 275.554C157.72 275.554 138.742 294.696 163.363 307.34C187.841 319.989 260.636 323.156 256.574 304.753Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.933 287.001C213.911 287.694 213.924 288.341 213.982 288.875C214.345 292.21 216.002 294.433 218.62 297.949C221.238 301.465 227.484 308.804 233.834 304.631C239.784 300.721 230.248 289.068 226.174 286.643C223.372 288.629 217.004 289.163 213.933 287.001Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.933 287.001C213.911 287.694 213.924 288.341 213.982 288.875C214.345 292.21 216.002 294.433 218.62 297.949C221.238 301.465 227.484 308.804 233.834 304.631C239.784 300.721 230.248 289.068 226.174 286.643C223.372 288.629 217.004 289.163 213.933 287.001Z"
          fill="url(#paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.951 285.903C178.552 287.707 166.103 295.291 168.998 300.984C172.194 307.27 184.83 300.121 187.761 298.065C190.692 296.009 193.185 293.526 194.444 291.159C194.891 290.32 195.133 288.393 195.21 286.435C192.281 287.944 186.037 288.63 182.951 285.903Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.951 285.903C178.552 287.707 166.103 295.291 168.998 300.984C172.194 307.27 184.83 300.121 187.761 298.065C190.692 296.009 193.185 293.526 194.444 291.159C194.891 290.32 195.133 288.393 195.21 286.435C192.281 287.944 186.037 288.63 182.951 285.903Z"
          fill="url(#paint1_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M190.517 172.422C176.031 188.281 180.47 272.977 180.938 281.825C180.996 290.479 194.708 288.291 196.412 285.521C198.164 282.356 203.509 204.96 205.254 204.947C207.129 204.96 210.852 280.649 212.623 285.521C214.73 289.998 225.591 288.919 227.36 285.521C229.475 281.728 239.464 196.512 223.675 172.422C222.071 169.974 192.753 169.974 190.517 172.422Z"
          fill="#6785FB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M190.517 172.422C176.031 188.281 180.47 272.977 180.938 281.825C180.996 290.479 194.708 288.291 196.412 285.521C198.164 282.356 203.509 204.96 205.254 204.947C207.129 204.96 210.852 280.649 212.623 285.521C214.73 289.998 225.591 288.919 227.36 285.521C229.475 281.728 239.464 196.512 223.675 172.422C222.071 169.974 192.753 169.974 190.517 172.422Z"
          fill="url(#paint2_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.483 75.7396C176.497 63.0185 187.102 54.7169 192.11 52.2653C204.743 45.7573 233.529 50.742 241.146 69.2092C248.787 87.6546 242.416 98.8023 242.368 107.604C242.416 116.243 255.797 134.169 238.452 148.281C221.202 162.383 182.245 158.932 175.225 140.162C168.245 121.502 177.061 112.019 177.345 102.272C177.623 93.3736 176.497 90.9094 176.483 75.7396Z"
          fill="#88A0FC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.483 75.7396C176.497 63.0185 187.102 54.7169 192.11 52.2653C204.743 45.7573 233.529 50.742 241.146 69.2092C248.787 87.6546 242.416 98.8023 242.368 107.604C242.416 116.243 255.797 134.169 238.452 148.281C221.202 162.383 182.245 158.932 175.225 140.162C168.245 121.502 177.061 112.019 177.345 102.272C177.623 93.3736 176.497 90.9094 176.483 75.7396Z"
          fill="#6785FB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239.352 148.281C223.284 161.418 188.379 159.322 178 143.801C189.522 123.618 195.282 109.395 195.282 101.132C195.282 86.6135 217.678 94.5007 224.399 95.1331C231.119 95.7656 223.432 107.061 233.492 114.929C241.701 121.35 247.217 134.382 242.472 145.305C241.586 146.311 240.552 147.305 239.352 148.281Z"
          fill="url(#paint3_linear)"
        />
        <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="177" y="92" width="68" height="65">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M239.352 148.281C223.284 161.418 188.379 159.322 178 143.801C189.522 123.618 195.282 109.395 195.282 101.132C195.282 86.6135 217.678 94.5007 224.399 95.1331C231.119 95.7656 223.432 107.061 233.492 114.929C241.701 121.35 247.217 134.382 242.472 145.305C241.586 146.311 240.552 147.305 239.352 148.281Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)"></g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M208 118.862C207.667 118.848 207.334 118.841 207 118.841C206.664 118.841 206.329 118.848 205.997 118.862C205.848 118.848 205.692 118.841 205.526 118.841C193.739 118.793 182.6 123.417 171.632 145.083C170.341 147.416 154.869 120.531 155.053 118.102C154.869 115.643 154.221 103.852 152.842 102.948C151.089 102.661 149.833 103.639 151 109.231C151.67 115.393 131.564 100.817 130.368 106.275C128.665 111.998 133.62 117.623 142.526 119.58C151.38 122.071 150.066 121.077 151.368 126.973C152.655 133.124 160.672 160.398 171.632 160.976C174.649 161.131 179.683 157.797 185.061 152.943L190.421 172.434C195.936 174.898 201.462 176.13 207 176.13C212.529 176.13 218.055 174.898 223.579 172.434L228.938 152.943C234.317 157.797 239.351 161.131 242.368 160.976C253.328 160.398 261.345 133.124 262.632 126.973C263.934 121.077 262.62 122.071 271.474 119.58C280.38 117.623 285.334 111.998 283.632 106.275C282.436 100.817 262.33 115.393 263 109.231C264.167 103.639 262.911 102.661 261.158 102.948C259.778 103.852 259.131 115.643 258.947 118.102C259.131 120.531 243.658 147.416 242.368 145.083C231.4 123.417 220.261 118.793 208.474 118.841C208.307 118.841 208.15 118.848 208 118.862Z"
          fill="#FFC62C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M208 118.862C207.667 118.848 207.334 118.841 207 118.841C206.664 118.841 206.329 118.848 205.997 118.862C205.848 118.848 205.692 118.841 205.526 118.841C193.739 118.793 182.6 123.417 171.632 145.083C170.341 147.416 154.869 120.531 155.053 118.102C154.869 115.643 154.221 103.852 152.842 102.948C151.089 102.661 149.833 103.639 151 109.231C151.67 115.393 131.564 100.817 130.368 106.275C128.665 111.998 133.62 117.623 142.526 119.58C151.38 122.071 150.066 121.077 151.368 126.973C152.655 133.124 160.672 160.398 171.632 160.976C174.649 161.131 179.683 157.797 185.061 152.943L190.421 172.434C195.936 174.898 201.462 176.13 207 176.13C212.529 176.13 218.055 174.898 223.579 172.434L228.938 152.943C234.317 157.797 239.351 161.131 242.368 160.976C253.328 160.398 261.345 133.124 262.632 126.973C263.934 121.077 262.62 122.071 271.474 119.58C280.38 117.623 285.334 111.998 283.632 106.275C282.436 100.817 262.33 115.393 263 109.231C264.167 103.639 262.911 102.661 261.158 102.948C259.778 103.852 259.131 115.643 258.947 118.102C259.131 120.531 243.658 147.416 242.368 145.083C231.4 123.417 220.261 118.793 208.474 118.841C208.307 118.841 208.15 118.848 208 118.862Z"
          fill="url(#paint4_linear)"
        />
        <mask
          id="mask1"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="130"
          y="102"
          width="154"
          height="75"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208 118.862C207.667 118.848 207.334 118.841 207 118.841C206.664 118.841 206.329 118.848 205.997 118.862C205.848 118.848 205.692 118.841 205.526 118.841C193.739 118.793 182.6 123.417 171.632 145.083C170.341 147.416 154.869 120.531 155.053 118.102C154.869 115.643 154.221 103.852 152.842 102.948C151.089 102.661 149.833 103.639 151 109.231C151.67 115.393 131.564 100.817 130.368 106.275C128.665 111.998 133.62 117.623 142.526 119.58C151.38 122.071 150.066 121.077 151.368 126.973C152.655 133.124 160.672 160.398 171.632 160.976C174.649 161.131 179.683 157.797 185.061 152.943L190.421 172.434C195.936 174.898 201.462 176.13 207 176.13C212.529 176.13 218.055 174.898 223.579 172.434L228.938 152.943C234.317 157.797 239.351 161.131 242.368 160.976C253.328 160.398 261.345 133.124 262.632 126.973C263.934 121.077 262.62 122.071 271.474 119.58C280.38 117.623 285.334 111.998 283.632 106.275C282.436 100.817 262.33 115.393 263 109.231C264.167 103.639 262.911 102.661 261.158 102.948C259.778 103.852 259.131 115.643 258.947 118.102C259.131 120.531 243.658 147.416 242.368 145.083C231.4 123.417 220.261 118.793 208.474 118.841C208.307 118.841 208.15 118.848 208 118.862Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208 118.862C207.667 118.848 207.334 118.841 207 118.841C206.664 118.841 206.329 118.848 205.997 118.862C205.848 118.848 205.692 118.841 205.526 118.841C193.739 118.793 182.6 123.417 171.632 145.083C170.341 147.416 154.869 120.531 155.053 118.102C154.869 115.643 154.221 103.852 152.842 102.948C151.089 102.661 149.833 103.639 151 109.231C151.67 115.393 131.564 100.817 130.368 106.275C128.665 111.998 133.62 117.623 142.526 119.58C151.38 122.071 150.066 121.077 151.368 126.973C152.655 133.124 160.672 160.398 171.632 160.976C174.649 161.131 179.683 157.797 185.061 152.943L190.421 172.434C195.936 174.898 201.462 176.13 207 176.13C212.529 176.13 218.055 174.898 223.579 172.434L228.938 152.943C234.317 157.797 239.351 161.131 242.368 160.976C253.328 160.398 261.345 133.124 262.632 126.973C263.934 121.077 262.62 122.071 271.474 119.58C280.38 117.623 285.334 111.998 283.632 106.275C282.436 100.817 262.33 115.393 263 109.231C264.167 103.639 262.911 102.661 261.158 102.948C259.778 103.852 259.131 115.643 258.947 118.102C259.131 120.531 243.658 147.416 242.368 145.083C231.4 123.417 220.261 118.793 208.474 118.841C208.307 118.841 208.15 118.848 208 118.862Z"
            stroke="white"
          />
        </mask>
        <g mask="url(#mask1)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M181.479 155.984C182.643 155.062 183.843 154.04 185.061 152.94L190.421 172.434C195.96 174.898 201.487 176.13 207 176.13C212.509 176.13 218.035 174.898 223.579 172.434L228.939 152.94C229.811 153.727 230.673 154.475 231.519 155.173C234.955 154.209 237.17 152.159 238.175 151.122C239.18 150.084 241.07 148.651 242.368 145.083C231.389 123.378 220.259 118.748 208.474 118.841C208.303 118.84 208.141 118.847 207.988 118.862C207.659 118.848 207.33 118.841 207 118.841C206.668 118.841 206.338 118.848 206.009 118.861C205.857 118.847 205.696 118.84 205.526 118.841C193.741 118.748 182.611 123.378 171.632 145.083C171.632 145.083 171.813 147.485 174.851 151.122C177.889 154.758 181.479 155.984 181.479 155.984Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M181.479 155.984C182.643 155.062 183.843 154.04 185.061 152.94L190.421 172.434C195.96 174.898 201.487 176.13 207 176.13C212.509 176.13 218.035 174.898 223.579 172.434L228.939 152.94C229.811 153.727 230.673 154.475 231.519 155.173C234.955 154.209 237.17 152.159 238.175 151.122C239.18 150.084 241.07 148.651 242.368 145.083C231.389 123.378 220.259 118.748 208.474 118.841C208.303 118.84 208.141 118.847 207.988 118.862C207.659 118.848 207.33 118.841 207 118.841C206.668 118.841 206.338 118.848 206.009 118.861C205.857 118.847 205.696 118.84 205.526 118.841C193.741 118.748 182.611 123.378 171.632 145.083C171.632 145.083 171.813 147.485 174.851 151.122C177.889 154.758 181.479 155.984 181.479 155.984Z"
            fill="url(#paint5_linear)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M207 108.492C203.541 108.492 200.737 111.689 200.737 115.632V119.832C200.737 123.776 203.541 126.972 207 126.972C210.459 126.972 213.263 123.776 213.263 119.832V115.632C213.263 111.689 210.459 108.492 207 108.492Z"
          fill="#FFC62C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M207 108.492C203.541 108.492 200.737 111.689 200.737 115.632V119.832C200.737 123.776 203.541 126.972 207 126.972C210.459 126.972 213.263 123.776 213.263 119.832V115.632C213.263 111.689 210.459 108.492 207 108.492Z"
          fill="url(#paint6_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M200.737 119.683C201.584 119.857 202.445 119.949 203.316 119.949C206.224 119.949 209.75 119.166 213.263 117.69L213.263 115.145C213.263 111.674 210.459 108.861 207 108.861C203.541 108.861 200.737 111.674 200.737 115.145V119.683Z"
          fill="url(#paint7_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.158 93.7086C236.558 87.9522 231.412 84.4626 229.842 87.0557C229.058 88.1726 228.804 89.235 228.737 90.3821C224.897 83.032 194.113 84.01 188.579 79.2939C188.519 81.0409 188.579 82.9074 188.579 84.838C188.579 86.5812 186.368 88.8865 186.368 97.035C186.368 105.108 194.861 116.255 204.421 116.255C211.705 116.255 222.858 111.341 228 102.949C232.284 102.944 229.607 99.448 233.158 93.7086Z"
          fill="#FFC62C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.158 93.7086C236.558 87.9522 231.412 84.4626 229.842 87.0557C229.058 88.1726 228.804 89.235 228.737 90.3821C224.897 83.032 194.113 84.01 188.579 79.2939C188.519 81.0409 188.579 82.9074 188.579 84.838C188.579 86.5812 186.368 88.8865 186.368 97.035C186.368 105.108 194.861 116.255 204.421 116.255C211.705 116.255 222.858 111.341 228 102.949C232.284 102.944 229.607 99.448 233.158 93.7086Z"
          fill="url(#paint8_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.737 90.3821C224.316 82.7357 194.132 84.0304 188.579 79.2939C188.545 81.0506 188.605 81.9378 188.579 83.852C188.605 85.6247 215.174 85.8157 228.737 90.3821Z"
          fill="url(#paint9_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="235.737"
            y1="286.643"
            x2="235.737"
            y2="305.879"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A7A7EE" stopOpacity="0.01" />
            <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="195.21"
            y1="285.903"
            x2="195.21"
            y2="303.515"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A7A7EE" stopOpacity="0.01" />
            <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="232.421"
            y1="170.586"
            x2="232.421"
            y2="288.488"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#403F9F" stopOpacity="0.01" />
            <stop offset="1" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="149.778"
            y1="102.387"
            x2="180.084"
            y2="178.723"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.646003" />
            <stop offset="1" stopColor="#1F1845" stopOpacity="0.01" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="283.964"
            y1="102.903"
            x2="283.964"
            y2="176.13"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC62C" stopOpacity="0.01" />
            <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="242.368"
            y1="118.84"
            x2="242.368"
            y2="176.13"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A7A7EE" stopOpacity="0.01" />
            <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="213.263"
            y1="108.492"
            x2="213.263"
            y2="126.972"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC62C" stopOpacity="0.01" />
            <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="207.286"
            y1="103.317"
            x2="218.233"
            y2="116.25"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.35" />
            <stop offset="1" stopColor="#FFC62C" stopOpacity="0.01" />
          </linearGradient>
          <linearGradient
            id="paint8_linear"
            x1="234.297"
            y1="79.2939"
            x2="234.297"
            y2="116.255"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC62C" stopOpacity="0.01" />
            <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint9_linear"
            x1="208.653"
            y1="73.7499"
            x2="214.341"
            y2="94.356"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.2" />
            <stop offset="1" stopColor="#FFC62C" stopOpacity="0.01" />
          </linearGradient>
        </defs>
      </svg>
    )
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="414"
      style={{ maxWidth: '415px', marginTop: '32px' }}
      height="318"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#A7A7EE" stopOpacity="0" />
          <stop offset="100%" stopColor="#4C55A0" stopOpacity=".2" />
        </linearGradient>
        <path
          id="a"
          d="M70.067 247.948c.022.693.009 1.34-.05 1.873-.362 3.334-2.019 5.558-4.637 9.073-2.618 3.516-8.864 10.853-15.214 6.68-5.95-3.909 3.586-15.56 7.66-17.985 2.802 1.986 9.17 2.52 12.24.359z"
        />
        <path
          id="c"
          d="M101.05 246.85c4.398 1.804 16.847 9.387 13.952 15.078-3.196 6.285-15.832-.863-18.763-2.918-2.931-2.056-5.424-4.539-6.683-6.905-.447-.84-.689-2.765-.767-4.723 2.93 1.509 9.174 2.194 12.26-.532z"
        />
        <linearGradient id="e" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#403F9F" stopOpacity="0" />
          <stop offset="100%" stopOpacity=".2" />
        </linearGradient>
        <path
          id="d"
          d="M93.483 133.39c14.486 15.857 10.047 100.537 9.579 109.383-.058 8.652-13.77 6.465-15.474 3.695-1.752-3.164-7.097-80.546-8.842-80.558-1.875.012-5.598 75.688-7.369 80.558-2.107 4.477-12.968 3.398-14.737 0-2.115-3.792-12.104-88.991 3.685-113.078 1.604-2.447 30.922-2.447 33.158 0z"
        />
        <linearGradient id="g" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#1F1845" stopOpacity="0" />
          <stop offset="100%" stopOpacity=".2" />
        </linearGradient>
        <path
          id="f"
          d="M4.155 26.826c.015-12.719 10.62-21.02 15.627-23.47 12.634-6.507 41.42-1.523 49.037 16.94 7.64 18.442 1.27 29.588 1.222 38.389.048 8.636 13.429 26.559-3.917 40.668-17.25 14.1-56.206 10.65-63.226-8.117-6.98-18.656 1.836-28.137 2.12-37.882.278-8.898-.848-11.361-.863-26.528z"
        />
        <linearGradient id="h" x1="16.126%" x2="64.018%" y1="-8.507%" y2="108.976%">
          <stop offset="0%" stopOpacity=".646" />
          <stop offset="100%" stopColor="#1F1845" stopOpacity="0" />
        </linearGradient>
        <path
          id="i"
          d="M67.025 99.353c-16.069 13.135-50.973 11.04-61.352-4.478 11.521-20.18 17.282-34.4 17.282-42.662 0-14.516 22.396-6.63 29.116-5.998 6.72.633-.966 11.926 9.094 19.793 8.209 6.419 13.724 19.449 8.98 30.37a25.973 25.973 0 0 1-3.12 2.975z"
        />
        <linearGradient id="k" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#FFC62C" stopOpacity="0" />
          <stop offset="100%" stopColor="#FF6663" stopOpacity=".1" />
        </linearGradient>
        <path
          id="j"
          d="M76 16.811a23.775 23.775 0 0 1 2.003 0c.149-.015.305-.022.47-.02 11.788-.05 22.927 4.574 33.895 26.236 1.29 2.332 16.763-24.547 16.58-26.976.183-2.458.83-14.247 2.21-15.151 1.753-.287 3.009.69 1.842 6.282-.67 6.16 19.436-8.413 20.632-2.956 1.703 5.722-3.252 11.346-12.158 13.303-8.854 2.49-7.54 1.497-8.842 7.39-1.287 6.15-9.304 33.42-20.264 33.998-3.017.155-8.05-3.179-13.43-8.031l-5.36 19.487c-5.514 2.463-11.04 3.695-16.578 3.695-5.53 0-11.056-1.232-16.579-3.695l-5.36-19.487c-5.378 4.852-10.412 8.186-13.43 8.031C30.673 58.34 22.656 31.07 21.369 24.92c-1.302-5.894.012-4.9-8.842-7.39C3.62 15.571-1.334 9.947.368 4.225 1.564-1.231 21.67 13.342 21 7.182 19.833 1.591 21.089.612 22.842.9c1.38.904 2.027 12.693 2.21 15.151-.183 2.429 15.29 29.308 16.58 26.976C52.6 21.365 63.739 16.741 75.526 16.79c.167 0 .324.007.474.021z"
        />
        <path
          id="m"
          d="M102.52 53.926a72.91 72.91 0 0 1-3.58-3.043l-5.361 19.49c-5.54 2.463-11.066 3.695-16.579 3.695-5.509 0-11.035-1.232-16.579-3.695l-5.36-19.49a77.199 77.199 0 0 1-2.58 2.232c-3.436-.964-5.651-3.014-6.656-4.05-1.005-1.038-2.895-2.47-4.193-6.038 10.98-21.7 22.109-26.33 33.894-26.237.171-.001.333.006.486.02a23.775 23.775 0 0 1 1.979 0c.152-.014.313-.021.483-.02 11.785-.093 22.915 4.536 33.894 26.237-.12 1.601-1.193 3.613-3.219 6.037-2.025 2.424-4.235 4.045-6.628 4.862z"
        />
        <path
          id="n"
          d="M6.263 0c3.46 0 6.263 3.196 6.263 7.139v4.199c0 3.943-2.804 7.139-6.263 7.139S0 15.28 0 11.338v-4.2C0 3.197 2.804 0 6.263 0z"
        />
        <linearGradient id="o" x1="100%" x2="0%" y1="0%" y2="104.574%">
          <stop offset="0%" stopOpacity=".35" />
          <stop offset="100%" stopColor="#FFC62C" stopOpacity="0" />
        </linearGradient>
        <path
          id="p"
          d="M2.006 14.877c-3.4-5.755 1.746-9.244 3.316-6.652.783 1.117 1.038 2.18 1.105 3.326C10.267 4.202 41.05 5.181 46.585.465c.06 1.746 0 3.613 0 5.543 0 1.743 2.21 4.048 2.21 12.195 0 8.071-8.492 19.216-18.052 19.216-7.284 0-18.437-4.913-23.58-13.304-4.283-.005-1.607-3.5-5.157-9.238z"
        />
        <linearGradient id="q" x1="100%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stopOpacity=".2" />
          <stop offset="100%" stopColor="#FFC62C" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F2F2F4"
          d="M0 187.693c0-52.838 26.757-74.974 93.177-89.891C159.639 83.126 168.447 0 254.707 0c86.499 0 200.177 147.997 142.823 232.999-57.332 85.086-216.58-30.983-268.737 7.55C76.534 278.9 0 240.385 0 187.694z"
        />
        <path
          fill="#E7E8EA"
          d="M157.427 304.697c4.417-18.452 44.147-29.194 71.473-29.194 27.38 0 46.358 19.139 21.737 31.78-24.478 12.647-97.273 15.813-93.21-2.586z"
        />
        <g transform="translate(130 39)">
          <use fill="#5678FB" xlinkHref="#a" />
          <use fill="url(#b)" xlinkHref="#a" />
        </g>
        <g transform="translate(130 39)">
          <use fill="#5678FB" xlinkHref="#c" />
          <use fill="url(#b)" xlinkHref="#c" />
        </g>
        <g transform="translate(130 39)">
          <use fill="#233061" xlinkHref="#d" />
          <use fill="url(#e)" xlinkHref="#d" />
        </g>
        <g transform="matrix(-1 0 0 1 241.673 48.9)">
          <use fill="#232F61" xlinkHref="#f" />
          <use fill="url(#g)" xlinkHref="#f" />
          <use fill="url(#h)" xlinkHref="#i" />
        </g>
        <g transform="translate(130 102.03)">
          <mask id="l" fill="#fff">
            <use xlinkHref="#j" />
          </mask>
          <use fill="#FFC62C" xlinkHref="#j" />
          <use fill="url(#k)" xlinkHref="#j" />
          <g mask="url(#l)">
            <use fill="#5678FB" xlinkHref="#m" />
            <use fill="url(#b)" xlinkHref="#m" />
          </g>
        </g>
        <g transform="translate(200.737 108.473)">
          <use fill="#FFC62C" xlinkHref="#n" />
          <use fill="url(#k)" xlinkHref="#n" />
        </g>
        <path
          fill="url(#o)"
          d="M12.526 11.19a12.8 12.8 0 0 1-2.579.266c-2.908 0-6.434-.784-9.947-2.259V6.652C0 3.182 2.804.37 6.263.37c3.46 0 6.263 2.812 6.263 6.282v4.537z"
          transform="translate(200.737 108.473)"
        />
        <g transform="translate(178.836 78.815)">
          <use fill="#FFC62C" xlinkHref="#p" />
          <use fill="url(#k)" xlinkHref="#p" />
        </g>
        <path
          fill="url(#q)"
          d="M6.427 11.551c4.42-7.645 34.605-6.35 40.158-11.086.033 1.756-.026 2.643 0 4.557-.026 1.773-26.596 1.963-40.158 6.529z"
          transform="translate(178.836 78.815)"
        />
      </g>
    </svg>
  )
}

export default EmptyStateNoResult
