import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export interface LogoProps {
  width?: string
  isInNav?: boolean
}

const coloredLogo = (props: LogoProps): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '150'} viewBox="0 0 150 41">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        d="M53.061 40.228V0h8.234v40.228h-8.234zM87.26 12.675h8.234v27.553h-8.234v-2.59C85.447 39.733 82.758 41 79.079 41c-7.19 0-13.118-6.337-13.118-14.548s5.928-14.549 13.119-14.549c3.678 0 6.367 1.268 8.179 3.362v-2.59zm-6.532 20.5c3.787 0 6.532-2.59 6.532-6.723s-2.745-6.724-6.532-6.724c-3.788 0-6.532 2.59-6.532 6.724 0 4.133 2.744 6.723 6.532 6.723zm29.037-15.596c1.098-3.692 4.776-5.455 8.234-5.455v9.368c-3.403-.551-8.234.827-8.234 6.282v12.454h-8.233V12.675h8.233v4.904zm32.002-4.904H150v27.553h-8.234v-2.59c-1.811 2.095-4.5 3.362-8.179 3.362-7.19 0-13.119-6.337-13.119-14.548s5.929-14.549 13.12-14.549c3.677 0 6.367 1.268 8.178 3.362v-2.59zm-6.532 20.5c3.788 0 6.532-2.59 6.532-6.723s-2.744-6.724-6.532-6.724c-3.787 0-6.532 2.59-6.532 6.724 0 4.133 2.745 6.723 6.532 6.723z"
      ></path>
      <path
        stroke="#DA1C85"
        strokeWidth="3.6"
        d="M34.935 1.868c-11.662.88-20.973 10.235-21.844 21.957h21.844V1.868z"
      ></path>
      <path
        fill="#6A2FEE"
        d="M1.02 15.375h24.49c0 6.393-2.33 12.24-6.185 16.729C14.647 37.552 7.725 41 0 41V16.395c0-.563.457-1.02 1.02-1.02z"
        style={{ mixBlendMode: 'multiply' }}
      ></path>
    </g>
  </svg>
)

const whiteLogo = (props: LogoProps): JSX.Element => (
  <svg width={props.width || '150'} viewBox="0 0 250 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88 66.7204V0H101.723V66.7204H88ZM144.996 21.0215H158.719V66.7204H144.996V62.4247C141.977 65.8979 137.494 68 131.364 68C119.38 68 109.499 57.4892 109.499 43.871C109.499 30.2527 119.38 19.7419 131.364 19.7419C137.494 19.7419 141.977 21.8441 144.996 25.3172V21.0215ZM134.109 55.0215C140.422 55.0215 144.996 50.7258 144.996 43.871C144.996 37.0161 140.422 32.7204 134.109 32.7204C127.796 32.7204 123.222 37.0161 123.222 43.871C123.222 50.7258 127.796 55.0215 134.109 55.0215ZM182.505 29.1559C184.335 23.0323 190.465 20.1075 196.228 20.1075V35.6452C190.556 34.7312 182.505 37.0161 182.505 46.0645V66.7204H168.782V21.0215H182.505V29.1559ZM235.842 21.0215H249.565V66.7204H235.842V62.4247C232.823 65.8979 228.34 68 222.21 68C210.226 68 200.345 57.4892 200.345 43.871C200.345 30.2527 210.226 19.7419 222.21 19.7419C228.34 19.7419 232.823 21.8441 235.842 25.3172V21.0215ZM224.955 55.0215C231.267 55.0215 235.842 50.7258 235.842 43.871C235.842 37.0161 231.267 32.7204 224.955 32.7204C218.642 32.7204 214.068 37.0161 214.068 43.871C214.068 50.7258 218.642 55.0215 224.955 55.0215Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.2246 0L61.2246 40.8C61.2246 41.7389 60.4635 42.5 59.5246 42.5L18.7076 42.5C18.7076 19.0279 37.7431 0 61.2246 0Z"
      fill="white"
      fillOpacity="0.696473"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.000404358 27.2C-0.000404358 26.2611 0.760712 25.5 1.6996 25.5L42.5166 25.5C42.5166 36.1035 38.6319 45.8 32.2076 53.245C24.4108 62.2806 12.8734 68 -0.000404358 68L-0.000404358 27.2Z"
      fill="white"
    />
  </svg>
)

const navLogo = (props: LogoProps): JSX.Element => (
  <svg width={props.width || '88'} height="24" viewBox="0 0 88 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        id="svg_2"
        d="m31.12925,23.54839l0,-23.54839l4.83047,0l0,23.54839l-4.83047,0zm20.06256,-16.12904l4.83047,0l0,16.12904l-4.83047,0l0,-1.51613c-1.0627,1.2258 -2.64066,1.96774 -4.79827,1.96774c-4.21861,0 -7.69655,-3.70968 -7.69655,-8.51613s3.47794,-8.51613 7.69655,-8.51613c2.15761,0 3.73557,0.74194 4.79827,1.96774l0,-1.51613zm-3.83217,12c2.22201,0 3.83217,-1.51612 3.83217,-3.93548c0,-2.41935 -1.61016,-3.93548 -3.83217,-3.93548c-2.22202,0 -3.83218,1.51613 -3.83218,3.93548c0,2.41936 1.61016,3.93548 3.83218,3.93548zm17.03546,-9.12903c0.64406,-2.16129 2.80167,-3.19355 4.83047,-3.19355l0,5.48388c-1.9966,-0.32259 -4.83047,0.48387 -4.83047,3.67741l0,7.29033l-4.83047,0l0,-16.12904l4.83047,0l0,2.87097zm18.77443,-2.87097l4.83047,0l0,16.12904l-4.83047,0l0,-1.51613c-1.0627,1.2258 -2.64066,1.96774 -4.79827,1.96774c-4.21861,0 -7.69655,-3.70968 -7.69655,-8.51613s3.47794,-8.51613 7.69655,-8.51613c2.15761,0 3.73557,0.74194 4.79827,1.96774l0,-1.51613zm-3.83218,12c2.22202,0 3.83218,-1.51612 3.83218,-3.93548c0,-2.41935 -1.61016,-3.93548 -3.83218,-3.93548s-3.83217,1.51613 -3.83217,3.93548c0,2.41936 1.61016,3.93548 3.83217,3.93548z"
      ></path>
      <path
        d="m0.599996,9l14.36599,0c0,3.7424 -1.36741,7.1647 -3.62878,9.79237c-2.74447,3.18901 -6.80563,5.20763 -11.33721,5.20763l0,-14.4c0,-0.33137 0.26863,-0.6 0.6,-0.6z"
        id="left"
      ></path>
      <path
        fillOpacity="0.696473"
        d="m21.551024,0l0,14.4c0,0.33137 -0.26863,0.6 -0.6,0.6l-14.36599,0c0,-8.28427 6.7005,-15 14.96599,-15z"
        id="right"
      ></path>
    </g>
  </svg>
)

export const StyledLogo: React.FunctionComponent<LogoProps> = (props) => {
  const themeContext = useContext(ThemeContext)
  if (props.isInNav) {
    return navLogo(props)
  }
  return themeContext.surface === '#FFFFFF' ? coloredLogo(props) : whiteLogo(props)
}
