import React from 'react'

export const IconCheckboxSquare: React.FC = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title>checkboxSquare</title>
      <path
        d="M15.9997 7.33325H7.99967C7.63101 7.33325 7.33301 7.63125 7.33301 7.99992V15.9999C7.33301 16.3686 7.63101 16.6666 7.99967 16.6666H15.9997C16.3683 16.6666 16.6663 16.3686 16.6663 15.9999V7.99992C16.6663 7.63125 16.3683 7.33325 15.9997 7.33325ZM15.333 15.3333H8.66634V8.66659H15.333V15.3333Z"
        id="box"
      ></path>
      <path
        d="M11.8043 13.8046C11.6743 13.9346 11.5037 13.9999 11.333 13.9999C11.1623 13.9999 10.9917 13.9346 10.8617 13.8046L9.52834 12.4713L10.471 11.5286L11.333 12.3906L13.8617 9.86192L14.8043 10.8046L11.8043 13.8046Z"
        id="checkmark"
      ></path>
    </svg>
  )
}
