import React from 'react'

export const IconPersonAttention: React.FC = () => {
  return (
    <svg width="33" height="35" viewBox="0 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4995 33.0001C28.1543 33.0001 31.1171 30.0372 31.1171 26.3824C31.1171 22.7276 28.1543 19.7648 24.4995 19.7648C20.8447 19.7648 17.8818 22.7276 17.8818 26.3824C17.8818 30.0372 20.8447 33.0001 24.4995 33.0001ZM25.3818 23.2942H24.0583V26.8236H25.3818V23.2942ZM25.3818 28.1471H24.0583V29.4707H25.3818V28.1471Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0064 4.08429C11.0645 3.37734 12.3084 3 13.5809 3C15.2872 3 16.9237 3.67785 18.1303 4.88442C19.3369 6.091 20.0147 7.72747 20.0147 9.43382C20.0147 10.7063 19.6374 11.9502 18.9304 13.0083C18.2235 14.0663 17.2186 14.8909 16.043 15.3779C14.8674 15.8649 13.5737 15.9923 12.3257 15.744C11.0777 15.4958 9.93127 14.883 9.03148 13.9832C8.1317 13.0834 7.51893 11.937 7.27068 10.689C7.02243 9.44096 7.14984 8.14733 7.6368 6.97171C8.12376 5.79608 8.9484 4.79125 10.0064 4.08429ZM11.4362 12.6435C12.071 13.0677 12.8174 13.2941 13.5809 13.2941C14.6047 13.2941 15.5866 12.8874 16.3105 12.1635C17.0345 11.4395 17.4412 10.4576 17.4412 9.43382C17.4412 8.67033 17.2148 7.92398 16.7906 7.28916C16.3664 6.65434 15.7635 6.15955 15.0582 5.86738C14.3528 5.5752 13.5766 5.49875 12.8278 5.6477C12.079 5.79665 11.3911 6.16431 10.8512 6.70418C10.3114 7.24406 9.94371 7.93189 9.79476 8.68072C9.64581 9.42954 9.72226 10.2057 10.0144 10.9111C10.3066 11.6165 10.8014 12.2194 11.4362 12.6435ZM22.7286 20.0045C22.6615 19.9328 22.5932 19.8622 22.5236 19.7926C20.8344 18.1034 18.5433 17.1544 16.1544 17.1544H11.0074C8.61845 17.1544 6.3274 18.1034 4.63819 19.7926C2.94899 21.4818 2 23.7729 2 26.1618V27.4485H4.57353V26.1618C4.57353 24.4554 5.25138 22.8189 6.45795 21.6124C7.66453 20.4058 9.301 19.7279 11.0074 19.7279H16.1544C17.6842 19.7279 19.1579 20.2728 20.3159 21.255C21.0154 20.6836 21.8339 20.2524 22.7286 20.0045Z"
      />
    </svg>
  )
}
