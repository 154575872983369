import React from 'react'
export const IconSuccess: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <path d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-1.106c6.017 0 10.894-4.877 10.894-10.894S18.017 1.106 12 1.106 1.106 5.983 1.106 12 5.983 22.894 12 22.894z" />
        <path d="M9.523 15.488l8.539-8.331a.559.559 0 0 1 .777 0c.215.21.214.551 0 .76L9.529 17l-4.364-4.188a.529.529 0 0 1-.008-.76.559.559 0 0 1 .778-.007l3.588 3.443z" />
      </g>
    </svg>
  )
}
