import * as React from 'react'

export const IconOptions: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_480_15624" maskUnits="userSpaceOnUse" x="9" y="0" width="6" height="24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 0.700012C10.5002 0.700012 9.2002 2.00001 9.2002 3.50001C9.2002 5.00001 10.5002 6.30001 12.0002 6.30001C13.5002 6.30001 14.8002 5.00001 14.8002 3.50001C14.8002 2.00001 13.5002 0.700012 12.0002 0.700012ZM12.0002 9.20001C10.5002 9.20001 9.2002 10.5 9.2002 12C9.2002 13.5 10.5002 14.8 12.0002 14.8C13.5002 14.8 14.8002 13.5 14.8002 12C14.8002 10.5 13.5002 9.20001 12.0002 9.20001ZM9.2002 20.4C9.2002 18.9 10.5002 17.6 12.0002 17.6C13.5002 17.6 14.8002 18.9 14.8002 20.4C14.8002 21.9 13.5002 23.2 12.0002 23.2C10.5002 23.2 9.2002 22 9.2002 20.4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_480_15624)">
      <path d="M24 0H0V24H24V0Z" />
    </g>
  </svg>
)
