import React from 'react'

export const IconReport: React.FC = () => {
  return (
    <svg width="33" height="35" viewBox="0 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 2C28.5626 2 30 2.95903 30 4.94231V31.4231C30 33.4064 28.5626 34.3654 27 34.3654H6C4.43742 34.3654 3 33.4064 3 31.4231V4.94231C3 2.95903 4.43742 2 6 2H27ZM6 4.94231V31.4231H27V4.94231H6ZM8.40332 7.88477H12.3264V10.8271H8.40332V7.88477ZM15.2687 7.88477H24.0956V10.8271H15.2687V7.88477ZM8.40332 13.7694H12.3235V16.7117H8.40332V13.7694ZM15.2687 13.7694H24.0956V16.7117H15.2687V13.7694ZM8.40332 19.654H12.3235L12.3264 22.5963H8.40332V19.654ZM15.2687 19.654H24.0956V22.5963H15.2687V19.654ZM8.40332 25.5386H12.3264V28.4809H8.40332V25.5386ZM15.2687 25.5386H24.0956V28.4809H15.2687V25.5386Z"
      />
    </svg>
  )
}
