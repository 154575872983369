import React from 'react'

export const IconWork: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <path d="M17.617 7.77h-2.462V6.737A1.74 1.74 0 0 0 13.418 5H9.94A1.737 1.737 0 0 0 8.2 6.737v1.03H5.737A1.73 1.73 0 0 0 4 9.507v7.466c0 .96.777 1.737 1.737 1.737h11.88a1.735 1.735 0 0 0 1.737-1.737V9.507a1.735 1.735 0 0 0-1.736-1.737zm-3.838 0H9.62V6.737c0-.193.157-.349.349-.349h3.449a.348.348 0 0 1 .345.349l.016 1.033z" />
      </g>
    </svg>
  )
}
