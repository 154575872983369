import React from 'react'

export const IconCloudUpload: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 16 16">
      <path d="M8.667 12.667v-2.667h2l-2.667-2.667-2.667 2.667h2v2.667z" />
      <path d="M4.667 12.667h1.333v-1.333h-1.333c-1.103 0-2-0.897-2-2 0-0.936 0.799-1.837 1.782-2.010l0.387-0.068 0.128-0.372c0.469-1.367 1.633-2.217 3.036-2.217 1.838 0 3.333 1.495 3.333 3.333v0.667h0.667c0.735 0 1.333 0.598 1.333 1.333s-0.598 1.333-1.333 1.333h-2v1.333h2c1.471 0 2.667-1.196 2.667-2.667-0.002-1.249-0.86-2.297-2.019-2.588l-0.018-0.004c-0.291-2.295-2.256-4.075-4.629-4.075-1.837 0-3.433 1.074-4.162 2.767-1.432 0.428-2.505 1.78-2.505 3.233 0 1.838 1.495 3.333 3.333 3.333z" />
    </svg>
  )
}
