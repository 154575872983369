import React from 'react'

export const IconCheckCircle: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M8 1.333C4.324 1.333 1.333 4.324 1.333 8S4.324 14.667 8 14.667 14.667 11.676 14.667 8 11.676 1.333 8 1.333zm0 12c-2.941 0-5.333-2.393-5.333-5.333S5.06 2.667 8 2.667 13.333 5.06 13.333 8 10.94 13.333 8 13.333z" />
      <path d="M6.666 9.058l-1.533-1.53-.941.944 2.004 2a.661.661 0 0 0 .942-.001l4.333-4.333-.943-.943-3.863 3.863z" />
    </svg>
  )
}
