import React from 'react'

export const IconProfile: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22 22">
      <path d="M11 22c6.054 0 11-4.915 11-11S17.054 0 11 0 0 4.946 0 11s4.946 11 11 11zm-6.654-3.837c1.799-2.248 4.166-3.477 6.654-3.477 2.488 0 4.855 1.229 6.654 3.477A9.73 9.73 0 0 1 11 20.771a9.717 9.717 0 0 1-6.654-2.608zM11 1.2c5.395 0 9.801 4.405 9.801 9.8a9.727 9.727 0 0 1-2.308 6.295c-2.038-2.457-4.676-3.806-7.493-3.806s-5.455 1.349-7.493 3.806A9.727 9.727 0 0 1 1.199 11c0-5.396 4.406-9.801 9.8-9.801H11z" />
      <path d="M11 13c2.198 0 4-1.802 4-4s-1.802-4-4-4-4 1.802-4 4c0 2.196 1.802 4 4 4zm0-7c1.648 0 3 1.353 3 3s-1.352 3-3 3-3-1.353-3-3 1.352-3 3-3z" />
    </svg>
  )
}
