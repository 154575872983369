import React from 'react'

export const IconExport: React.FC = () => {
  return (
    <svg width="33" height="35" viewBox="0 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0003 22.25H17.8826V9.92308H22.2062L16.4415 3L10.6768 9.92308H15.0003V22.25Z" fill="#46926D" />
      <path d="M6.72222 30.9999H26.5C28.0013 30.9999 29 29.7614 29 28.2386V15.8125C29 14.2896 28.0013 13.0512 26.5 13.0512H20.3333V15.8125H26.5V28.2386H6.72222V15.8125H12.1667V13.0512H6.72222C5.22092 13.0512 4 14.2896 4 15.8125V28.2386C4 29.7614 5.22092 30.9999 6.72222 30.9999Z" />
    </svg>
  )
}
