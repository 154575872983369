import React from 'react'

export const IconSchool: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <path d="M19.86 10.052V8.864a.15.15 0 0 0-.15-.149h-6.537V7.378a.15.15 0 0 1 .149-.149h1.93a.15.15 0 0 0 .15-.15V5.15a.15.15 0 0 0-.15-.149h-3.416a.15.15 0 0 0-.15.15v3.565H5.15a.15.15 0 0 0-.149.15v1.187c0 .082.067.149.15.149h.593v8.173h-.594a.15.15 0 0 0-.149.15v1.187c0 .082.067.149.15.149h14.56a.15.15 0 0 0 .15-.15v-1.187a.15.15 0 0 0-.15-.15h-.593v-8.172h.594a.15.15 0 0 0 .149-.15zM8.566 17.63H7.378a.15.15 0 0 1-.15-.15v-1.187a.15.15 0 0 1 .15-.15h1.188a.15.15 0 0 1 .149.15v1.188a.15.15 0 0 1-.15.149zm0-2.972H7.378a.15.15 0 0 1-.15-.15v-1.187a.15.15 0 0 1 .15-.15h1.188a.15.15 0 0 1 .149.15v1.188a.15.15 0 0 1-.15.149zm0-2.972H7.378a.15.15 0 0 1-.15-.15V10.35a.15.15 0 0 1 .15-.15h1.188a.15.15 0 0 1 .149.15v1.188a.15.15 0 0 1-.15.149zm5.944 6.687h-4.16a.15.15 0 0 1-.15-.15v-2.696c0-1.209.905-2.288 2.11-2.352a2.23 2.23 0 0 1 2.349 2.226v2.823a.15.15 0 0 1-.15.149zm0-6.687h-4.16a.15.15 0 0 1-.15-.15V10.35a.15.15 0 0 1 .15-.15h4.16a.15.15 0 0 1 .149.15v1.188a.15.15 0 0 1-.15.149zm2.972 5.944h-1.188a.15.15 0 0 1-.15-.15v-1.187a.15.15 0 0 1 .15-.15h1.188a.15.15 0 0 1 .149.15v1.188a.15.15 0 0 1-.15.149zm0-2.972h-1.188a.15.15 0 0 1-.15-.15v-1.187a.15.15 0 0 1 .15-.15h1.188a.15.15 0 0 1 .149.15v1.188a.15.15 0 0 1-.15.149zm0-2.972h-1.188a.15.15 0 0 1-.15-.15V10.35a.15.15 0 0 1 .15-.15h1.188a.15.15 0 0 1 .149.15v1.188a.15.15 0 0 1-.15.149z" />
      </g>
    </svg>
  )
}
