import React from 'react'

export const IconVaccation: React.FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M5.7675 16.2234L4 17.9909L10.1862 20.6422L12.8388 26.8297L14.6062 25.0622L13.7225 20.6422L17.8637 16.5009L22.3763 26.1309L24.05 24.4572L22.5625 11.8034L26.0975 8.26719C27.0737 7.29094 27.0737 5.70844 26.0975 4.73219C25.1212 3.75594 23.5375 3.75594 22.5625 4.73219L18.9337 8.36094L6.28 6.87219L4.69875 8.45469L14.2538 13.0422L10.1875 17.1084L5.7675 16.2234Z" />
      </g>
    </svg>
  )
}
