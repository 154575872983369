import React from 'react'

export interface EmptyStateWaitingProps {
  readonly darkMode?: boolean
}

const EmptyStateWaiting: React.FunctionComponent<EmptyStateWaitingProps> = ({ darkMode }) => {
  if (darkMode) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="460" height="232" viewBox="0 0 460 232" fill="none">
        <g id="empty_state_waiting">
          <g id="Bench">
            <path
              id="Detail"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M70.5478 9.02041C174.396 -33.3889 248.704 87.5097 355.258 87.4868C462.026 87.5097 462.026 173.343 435.884 199.067C418.237 216.433 397.96 226.632 375.055 229.662H38.5133C-9.15781 110.888 1.52033 37.3407 70.5478 9.02041Z"
              fill="white"
              fillOpacity="0.05"
            />
            <path
              id="Detail_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M271.756 44.5055C284.878 44.5055 438.9 44.5055 438.9 44.5055C438.9 44.5055 443.43 26.4392 411.693 32.4613C379.957 38.4834 384.924 21.4927 365.93 17.5702C346.936 13.6476 337.833 29.6323 308.877 21.4927C279.922 13.3531 258.634 44.5055 271.756 44.5055Z"
              fill="white"
              fillOpacity="0.05"
            />
            <path
              id="Detail_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 229.662C0 211.892 16.8949 198.707 34.9139 198.707C46.5568 198.707 56.8822 204.348 63.3062 213.046C67.1156 210.147 71.8701 208.426 77.0266 208.426C82.6068 208.426 87.7163 210.441 91.6666 213.784C95.7699 202.07 106.923 193.668 120.039 193.668C124.427 193.668 128.594 194.608 132.352 196.298C135.411 189.446 142.285 184.67 150.274 184.67C159.386 184.67 167.047 190.883 169.252 199.304C170.402 199.148 171.577 199.067 172.77 199.067C178.763 199.067 184.28 201.102 188.67 204.517C194.026 195.643 203.762 189.709 214.883 189.709C225.589 189.709 235.011 195.209 240.478 203.537C243.995 200.953 248.337 199.427 253.036 199.427C260.943 199.427 267.841 203.749 271.497 210.159C275.907 203.477 283.483 199.067 292.089 199.067C301.419 199.067 309.538 204.249 313.726 211.892C319.129 201.087 330.298 193.668 343.2 193.668C352.475 193.668 360.855 197.502 366.84 203.672C371.146 197.618 378.218 193.668 386.213 193.668C392.257 193.668 397.775 195.926 401.967 199.644C407.472 190.662 417.379 184.67 428.685 184.67C445.98 184.67 460 198.69 460 215.984C460 220.889 458.77 225.53 456.761 229.662H0Z"
              fill="#282F46"
            />
            <g id="Detail_4">
              <path
                id="Detail_5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M127.778 112.683H332.222C332.819 112.683 333.302 113.166 333.302 113.762V124.561C333.302 125.157 332.819 125.64 332.222 125.64H127.778C127.181 125.64 126.698 125.157 126.698 124.561V113.762C126.698 113.166 127.181 112.683 127.778 112.683Z"
                fill="#41465B"
              />
              <path
                id="Detail_6"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M127.778 129.6H332.222C332.819 129.6 333.302 130.083 333.302 130.679V141.478C333.302 142.074 332.819 142.557 332.222 142.557H127.778C127.181 142.557 126.698 142.074 126.698 141.478V130.679C126.698 130.083 127.181 129.6 127.778 129.6Z"
                fill="#41465B"
              />
              <path
                id="Detail_7"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M127.778 146.157H332.222C332.819 146.157 333.302 146.641 333.302 147.237V158.035C333.302 158.632 332.819 159.115 332.222 159.115H127.778C127.181 159.115 126.698 158.632 126.698 158.035V147.237C126.698 146.641 127.181 146.157 127.778 146.157Z"
                fill="#41465B"
              />
              <path
                id="Detail_8"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M116.98 166.313H343.02C343.617 166.313 344.1 166.797 344.1 167.393V178.191C344.1 178.788 343.617 179.271 343.02 179.271H116.98C116.383 179.271 115.9 178.788 115.9 178.191V167.393C115.9 166.797 116.383 166.313 116.98 166.313Z"
                fill="#41465B"
              />
              <path
                id="Detail_9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M147.214 112.683H150.814V166.313H147.214V112.683Z"
                fill="#41465B"
              />
              <path
                id="Detail_10"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M309.186 112.683H312.786V166.313H309.186V112.683Z"
                fill="#41465B"
              />
              <path
                id="Detail_11"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M138.936 179.271H150.814V228.583C150.814 229.179 150.33 229.663 149.734 229.663H140.016C139.419 229.663 138.936 229.179 138.936 228.583V179.271Z"
                fill="#41465B"
              />
              <path
                id="Detail_12"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M309.186 179.271H321.064V228.583C321.064 229.179 320.581 229.663 319.984 229.663H310.266C309.67 229.663 309.186 229.179 309.186 228.583V179.271Z"
                fill="#41465B"
              />
            </g>
          </g>
          <g id="Girl and Macbook">
            <g id="Hair">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M248.905 53.1959C237.508 55.8428 225.029 65.7588 231.666 94.1857C238.349 122.465 288.439 145.078 288.439 145.078L273.499 65.8613C273.499 65.8613 260.528 50.4383 248.905 53.1959Z"
                fill="#232F61"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M248.905 53.1959C237.508 55.8428 225.029 65.7588 231.666 94.1857C238.349 122.465 288.439 145.078 288.439 145.078L273.499 65.8613C273.499 65.8613 260.528 50.4383 248.905 53.1959Z"
                fill="#6785FB"
              />
            </g>
            <g id="Hand L">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M151.038 165.457C146.728 159.92 164.751 162.037 172.759 155.056C180.768 148.076 186.301 144.103 186.301 144.103L199.495 148.924C199.495 148.924 182.947 154.77 182.896 159.598C182.845 164.426 179.389 167.89 172.632 167.027C165.875 166.164 155.349 170.994 151.038 165.457Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M151.038 165.457C146.728 159.92 164.751 162.037 172.759 155.056C180.768 148.076 186.301 144.103 186.301 144.103L199.495 148.924C199.495 148.924 182.947 154.77 182.896 159.598C182.845 164.426 179.389 167.89 172.632 167.027C165.875 166.164 155.349 170.994 151.038 165.457Z"
                fill="url(#paint0_linear)"
              />
            </g>
            <g id="Hand L_2">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M289.245 155.729C285.377 167.738 266.441 164.6 254.014 164.6C250.158 164.6 227.134 138.303 225.69 138.303C224.246 138.303 187.51 159.938 187.51 159.938C187.51 159.938 181.765 160.04 178.153 155.729C174.54 151.417 175.789 147.19 175.789 147.19C175.789 147.19 215.666 114.91 225.69 114.91C235.714 114.91 261.164 141.515 266.012 141.515C278.844 141.515 293.113 143.719 289.245 155.729Z"
                fill="#232F61"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M289.245 155.729C285.377 167.738 266.441 164.6 254.014 164.6C250.158 164.6 227.134 138.303 225.69 138.303C224.246 138.303 187.51 159.938 187.51 159.938C187.51 159.938 181.765 160.04 178.153 155.729C174.54 151.417 175.789 147.19 175.789 147.19C175.789 147.19 215.666 114.91 225.69 114.91C235.714 114.91 261.164 141.515 266.012 141.515C278.844 141.515 293.113 143.719 289.245 155.729Z"
                fill="#6785FB"
              />
            </g>
            <g id="Hand L_3">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M261.941 106.401C255.422 106.401 253.649 137.416 253.649 139.586C253.649 141.756 262.214 143.841 263.229 142.753C264.245 141.665 272.207 127.272 272.974 120.93C273.742 114.587 268.46 106.401 261.941 106.401Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M261.941 106.401C255.422 106.401 253.649 137.416 253.649 139.586C253.649 141.756 262.214 143.841 263.229 142.753C264.245 141.665 272.207 127.272 272.974 120.93C273.742 114.587 268.46 106.401 261.941 106.401Z"
                fill="url(#paint1_linear)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M261.941 106.401C255.422 106.401 253.649 137.416 253.649 139.586C253.649 141.756 262.214 143.841 263.229 142.753C264.245 141.665 272.207 127.272 272.974 120.93C273.742 114.587 268.46 106.401 261.941 106.401Z"
                fill="url(#paint2_linear)"
              />
            </g>
            <g id="Hand R">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M229.074 150.229C224.819 151.931 220.863 153.796 219.737 154.936C218.61 156.076 218.148 158.515 218.148 160.702C218.148 162.89 218.03 166.871 222.316 166.009C226.601 165.147 233.585 157.311 233.585 154.936C233.585 152.561 233.329 148.526 229.074 150.229Z"
                fill="#FFC62C"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M229.074 150.229C224.819 151.931 220.863 153.796 219.737 154.936C218.61 156.076 218.148 158.515 218.148 160.702C218.148 162.89 218.03 166.871 222.316 166.009C226.601 165.147 233.585 157.311 233.585 154.936C233.585 152.561 233.329 148.526 229.074 150.229Z"
                fill="url(#paint3_linear)"
              />
            </g>
            <g id="Macbook">
              <path
                id="Detail_13"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M170.167 131.951H207.69L216.266 167.184H175.776C175.381 167.184 175.037 166.913 174.943 166.528L167.389 135.494C167.015 133.957 167.955 132.408 169.489 132.033C169.711 131.979 169.938 131.951 170.167 131.951Z"
                fill="#D6DFEA"
              />
              <path
                id="Logo"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M190.522 152.216C191.919 152.216 193.051 151.081 193.051 149.682C193.051 148.284 191.919 147.149 190.522 147.149C189.126 147.149 187.994 148.284 187.994 149.682C187.994 151.081 189.126 152.216 190.522 152.216Z"
                fill="#F2F5F9"
              />
              <path
                id="Detail_14"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M216.475 162.887H241.702C242.887 162.887 243.848 163.849 243.848 165.036C243.848 166.222 242.887 167.184 241.702 167.184H214.088V164.835L206.414 133.343C206.267 132.739 206.637 132.13 207.24 131.983C207.327 131.962 207.416 131.951 207.506 131.951C208.346 131.951 209.077 132.526 209.276 133.343L216.475 162.887Z"
                fill="#B6C5D9"
              />
            </g>
            <g id="Group">
              <g id="Body">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M258.499 110.565C258.499 85.8531 291.649 102.075 293.495 145.538C295.237 189.163 250.236 157.488 262.925 149.452C269.559 145.196 266.763 133.633 261.814 128.822C255.736 122.914 258.499 121.867 258.499 110.565Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M258.499 110.565C258.499 85.8531 291.649 102.075 293.495 145.538C295.237 189.163 250.236 157.488 262.925 149.452C269.559 145.196 266.763 133.633 261.814 128.822C255.736 122.914 258.499 121.867 258.499 110.565Z"
                  fill="url(#paint4_linear)"
                />
              </g>
              <mask
                id="mask0"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="257"
                y="98"
                width="37"
                height="71"
              >
                <g id="Body_2">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M258.499 110.565C258.499 85.8531 291.649 102.075 293.495 145.538C295.237 189.163 250.236 157.488 262.925 149.452C269.559 145.196 266.763 133.633 261.814 128.822C255.736 122.914 258.499 121.867 258.499 110.565Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M258.499 110.565C258.499 85.8531 291.649 102.075 293.495 145.538C295.237 189.163 250.236 157.488 262.925 149.452C269.559 145.196 266.763 133.633 261.814 128.822C255.736 122.914 258.499 121.867 258.499 110.565Z"
                    stroke="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask0)">
                <path
                  id="Sh"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M272.078 166.016C269.064 164.591 266.3 162.566 264.247 160.38C265.644 150.763 270.233 127.806 282.197 127.806C285.997 127.806 289.339 129.861 292.002 133.006C292.785 136.885 293.306 141.067 293.496 145.537C293.924 156.264 291.526 162.439 287.854 165.531C282.148 166.639 276.483 166.488 272.078 166.016Z"
                  fill="url(#paint5_linear)"
                />
                <path
                  id="Detail_15"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M257.065 109.384C261.254 109.384 264.65 105.621 264.65 100.978C264.65 96.3364 261.254 92.5732 257.065 92.5732C252.876 92.5732 249.48 96.3364 249.48 100.978C249.48 105.621 252.876 109.384 257.065 109.384Z"
                  fill="#111832"
                />
                <path
                  id="Detail_16"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M268.328 113.068C272.517 113.068 275.913 109.305 275.913 104.663C275.913 100.021 272.517 96.2578 268.328 96.2578C264.139 96.2578 260.743 100.021 260.743 104.663C260.743 109.305 264.139 113.068 268.328 113.068Z"
                  fill="#111832"
                />
              </g>
            </g>
            <g id="Neck">
              <g id="Neck_2">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M253.931 92.6817L266.377 84.5566L270.657 91.8846C272.899 95.7218 272.402 100.571 269.43 103.873C267.344 106.189 263.779 106.373 261.466 104.284C261.081 103.936 260.746 103.537 260.47 103.097L253.931 92.6817Z"
                  fill="#FFC62C"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M253.931 92.6817L266.377 84.5566L270.657 91.8846C272.899 95.7218 272.402 100.571 269.43 103.873C267.344 106.189 263.779 106.373 261.466 104.284C261.081 103.936 260.746 103.537 260.47 103.097L253.931 92.6817Z"
                  fill="url(#paint6_linear)"
                />
              </g>
              <path
                id="Sh_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M271.368 93.3608C269.65 98.2107 265.817 102.057 260.979 103.786C260.793 103.57 260.622 103.339 260.47 103.097L253.931 92.6817L266.377 84.5566L270.657 91.8846C270.936 92.3626 271.173 92.8563 271.368 93.3608Z"
                fill="url(#paint7_linear)"
              />
              <mask
                id="mask1"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="253"
                y="84"
                width="19"
                height="20"
              >
                <path
                  id="Sh_3"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M271.368 93.3608C269.65 98.2107 265.817 102.057 260.979 103.786C260.793 103.57 260.622 103.339 260.47 103.097L253.931 92.6817L266.377 84.5566L270.657 91.8846C270.936 92.3626 271.173 92.8563 271.368 93.3608Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask1)"></g>
            </g>
            <g id="Hand R_2">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M277.348 101.303C269.784 101.303 263.271 128.133 263.271 130.861C263.271 133.59 225.501 151.732 225.501 151.732C225.501 151.732 227.807 152.234 229.227 155.258C230.646 158.283 232.488 158.564 232.488 158.564C232.488 158.564 274.989 143.88 277.348 140.901C279.708 137.922 286.414 128.373 286.66 116.616C286.907 104.859 284.912 101.303 277.348 101.303Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M277.348 101.303C269.784 101.303 263.271 128.133 263.271 130.861C263.271 133.59 225.501 151.732 225.501 151.732C225.501 151.732 227.807 152.234 229.227 155.258C230.646 158.283 232.488 158.564 232.488 158.564C232.488 158.564 274.989 143.88 277.348 140.901C279.708 137.922 286.414 128.373 286.66 116.616C286.907 104.859 284.912 101.303 277.348 101.303Z"
                fill="url(#paint8_linear)"
              />
            </g>
            <path
              id="Line"
              d="M277.348 100.727C277.539 100.727 277.693 100.881 277.693 101.072C277.693 101.263 277.539 101.417 277.348 101.417C274.221 101.417 270.865 106.544 267.797 114.947C265.604 120.951 263.616 128.861 263.616 130.63C263.616 131.749 260.749 133.786 254.742 137.112C250.009 139.733 243.35 143.117 234.757 147.27C234.703 147.295 234.65 147.321 234.597 147.347C232.699 148.263 232.367 147.657 234.243 146.751C234.301 146.723 234.358 146.695 234.416 146.668C243.015 142.513 249.679 139.126 254.409 136.507C259.997 133.413 262.926 131.331 262.926 130.63C262.926 128.759 264.931 120.785 267.15 114.709C270.328 106.005 273.783 100.727 277.348 100.727Z"
              fill="white"
            />
            <g id="Hair_2">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M269.361 88.8889C264.735 99.953 264.855 114.246 270.74 127.576C276.703 140.941 309.923 155.704 299.931 124.352C289.598 92.8827 293.263 81.8966 285.221 67.2426C277.162 52.7613 263.972 46.5024 245.687 54.1166C227.749 61.7838 273.73 77.8377 269.361 88.8889Z"
                fill="#232F61"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M269.361 88.8889C264.735 99.953 264.855 114.246 270.74 127.576C276.703 140.941 309.923 155.704 299.931 124.352C289.598 92.8827 293.263 81.8966 285.221 67.2426C277.162 52.7613 263.972 46.5024 245.687 54.1166C227.749 61.7838 273.73 77.8377 269.361 88.8889Z"
                fill="#6785FB"
              />
            </g>
            <g id="Face">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M244.078 70.4668C244.461 70.4668 254.719 76.7994 268.442 77.6055C269.901 76.4801 273.473 76.4801 273.728 81.29C273.922 84.7039 272.757 88.3265 269.821 88.6589C267.957 95.0778 265.002 100.315 257.179 101.555C249.357 102.794 244.408 97.4196 242.699 93.9553C240.564 90.54 238.647 76.9429 238.791 75.533C241.087 74.4179 243.552 70.4668 244.078 70.4668Z"
                fill="#FFC62C"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M244.078 70.4668C244.461 70.4668 254.719 76.7994 268.442 77.6055C269.901 76.4801 273.473 76.4801 273.728 81.29C273.922 84.7039 272.757 88.3265 269.821 88.6589C267.957 95.0778 265.002 100.315 257.179 101.555C249.357 102.794 244.408 97.4196 242.699 93.9553C240.564 90.54 238.647 76.9429 238.791 75.533C241.087 74.4179 243.552 70.4668 244.078 70.4668Z"
                fill="url(#paint9_linear)"
              />
            </g>
            <path
              id="Sh_4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M269.996 84.8645C271.108 84.8645 272.01 83.9612 272.01 82.8468C272.01 81.7325 271.108 80.8291 269.996 80.8291C268.884 80.8291 267.982 81.7325 267.982 82.8468C267.982 83.9612 268.884 84.8645 269.996 84.8645Z"
              fill="url(#paint10_linear)"
            />
            <g id="Leg R">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M203.357 229.432C198.674 222.451 218.544 215.87 219.107 208.991C219.482 204.405 220.01 201.797 220.691 201.169L229.363 200.132C229.379 206.276 230.074 210.826 231.446 213.784C233.504 218.221 230.734 223.043 223.659 223.41C216.583 223.777 208.04 236.414 203.357 229.432Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M203.357 229.432C198.674 222.451 218.544 215.87 219.107 208.991C219.482 204.405 220.01 201.797 220.691 201.169L229.363 200.132C229.379 206.276 230.074 210.826 231.446 213.784C233.504 218.221 230.734 223.043 223.659 223.41C216.583 223.777 208.04 236.414 203.357 229.432Z"
                fill="url(#paint11_linear)"
              />
            </g>
            <g id="Leg R_2">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M273.732 174.783C266.363 174.783 250.338 174.783 245.659 174.783L231.951 205.651C231.951 205.651 229.805 208.132 224.822 208.132C219.839 208.132 218.555 205.651 218.555 205.651C218.555 205.651 221.538 153.897 235.796 150.756C268.719 143.504 293.366 147.632 293.366 147.632C293.366 147.632 295.275 174.783 273.732 174.783Z"
                fill="#232F61"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M273.732 174.783C266.363 174.783 250.338 174.783 245.659 174.783L231.951 205.651C231.951 205.651 229.805 208.132 224.822 208.132C219.839 208.132 218.555 205.651 218.555 205.651C218.555 205.651 221.538 153.897 235.796 150.756C268.719 143.504 293.366 147.632 293.366 147.632C293.366 147.632 295.275 174.783 273.732 174.783Z"
                fill="#6785FB"
              />
            </g>
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="151.672"
            y1="171.397"
            x2="201.896"
            y2="162.542"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A7A7EE" stopOpacity="0.01" />
            <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="253.649"
            y1="106.401"
            x2="253.649"
            y2="143.05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A7A7EE" stopOpacity="0.01" />
            <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="267.534"
            y1="115.311"
            x2="251.972"
            y2="113.887"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#464D89" stopOpacity="0.6" />
            <stop offset="1" stopColor="#A7A7EE" stopOpacity="0.01" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="218.148"
            y1="149.821"
            x2="218.148"
            y2="166.13"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC62C" stopOpacity="0.01" />
            <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="257.873"
            y1="98.9678"
            x2="257.873"
            y2="168.089"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A7A7EE" stopOpacity="0.01" />
            <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="249.598"
            y1="147.089"
            x2="286.753"
            y2="175.314"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#464D89" stopOpacity="0.3" />
            <stop offset="1" stopColor="#A7A7EE" stopOpacity="0.01" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="253.931"
            y1="84.5566"
            x2="253.931"
            y2="105.738"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC62C" stopOpacity="0.01" />
            <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="258.377"
            y1="82.8179"
            x2="245.903"
            y2="96.4292"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.1" />
            <stop offset="1" stopColor="#FF6663" stopOpacity="0.01" />
          </linearGradient>
          <linearGradient
            id="paint8_linear"
            x1="225.501"
            y1="101.303"
            x2="225.501"
            y2="158.564"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A7A7EE" stopOpacity="0.01" />
            <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint9_linear"
            x1="238.783"
            y1="70.4668"
            x2="238.783"
            y2="101.737"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC62C" stopOpacity="0.01" />
            <stop offset="1" stopColor="#FF6663" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint10_linear"
            x1="269.996"
            y1="78.8114"
            x2="265.961"
            y2="82.8393"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.2" />
            <stop offset="1" stopColor="#FF6663" stopOpacity="0.01" />
          </linearGradient>
          <linearGradient
            id="paint11_linear"
            x1="202.644"
            y1="200.132"
            x2="202.644"
            y2="231.532"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A7A7EE" stopOpacity="0.01" />
            <stop offset="1" stopColor="#4C55A0" stopOpacity="0.2" />
          </linearGradient>
        </defs>
      </svg>
    )
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="460" height="232" viewBox="0 0 460 232">
      <defs>
        <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#403F9F" stopOpacity="0" />
          <stop offset="100%" stopOpacity=".2" />
        </linearGradient>
        <path
          id="a"
          d="M100.904 2.533C89.508 5.18 77.03 15.096 83.666 43.523c4.455 18.853 23.38 35.817 56.773 50.892l-14.94-79.217C116.852 4.916 108.654.695 100.904 2.533z"
        />
        <linearGradient id="d" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#A7A7EE" stopOpacity="0" />
          <stop offset="100%" stopColor="#4C55A0" stopOpacity=".2" />
        </linearGradient>
        <path
          id="c"
          d="M22.5 80.94c6.2-3.283.987 14.098 6.47 23.197 3.656 6.066 6.468 11.146 8.436 15.239l-7.039 12.156c-1.922-11.541-4.466-17.608-7.63-18.2-4.745-.89-7.556-4.895-5.533-11.399 2.023-6.504-.906-17.71 5.295-20.993z"
        />
        <path
          id="e"
          d="M141.245 105.066c-3.868 12.01-22.804 8.872-35.231 8.872-3.856 0-26.88-26.298-28.324-26.298-.962 0-13.69 7.212-38.18 21.636-3.83.068-6.949-1.335-9.357-4.21-2.409-2.874-3.197-5.72-2.364-8.538 26.584-21.52 43.218-32.28 49.901-32.28 10.025 0 35.474 26.605 40.322 26.605 12.832 0 27.101 2.204 23.233 14.213z"
        />
        <linearGradient id="g" x1="64.781%" x2="-13.759%" y1="63.582%" y2="50%">
          <stop offset="0%" stopColor="#464D89" stopOpacity=".6" />
          <stop offset="100%" stopColor="#A7A7EE" stopOpacity="0" />
        </linearGradient>
        <path
          id="f"
          d="M113.941 55.739c-6.519 0-8.292 31.015-8.292 33.185 0 2.17 8.565 4.254 9.58 3.166 1.016-1.087 8.978-15.48 9.746-21.823.767-6.342-4.515-14.528-11.034-14.528z"
        />
        <linearGradient id="i" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#FFC62C" stopOpacity="0" />
          <stop offset="100%" stopColor="#FF6663" stopOpacity=".1" />
        </linearGradient>
        <path
          id="h"
          d="M81.074 99.566c-4.255 1.703-8.211 3.567-9.337 4.707-1.127 1.14-1.589 3.58-1.589 5.767 0 2.188-.118 6.17 4.168 5.307 4.285-.862 11.27-8.699 11.27-11.074s-.257-6.41-4.512-4.707z"
        />
        <path
          id="j"
          d="M.744 11.775c0-24.713 33.15-8.49 34.997 34.972 1.741 43.625-43.26 11.95-30.57 3.915 6.634-4.257 3.838-15.82-1.111-20.63C-2.018 24.123.744 23.076.744 11.775z"
        />
        <linearGradient id="k" x1="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#464D89" stopOpacity=".3" />
          <stop offset="100%" stopColor="#A7A7EE" stopOpacity="0" />
        </linearGradient>
        <path
          id="m"
          d="M.2 8.168L12.645.043l4.28 7.328a10.219 10.219 0 0 1-1.227 11.988 5.631 5.631 0 0 1-8.959-.776L.199 8.168z"
        />
        <linearGradient id="o" x1="64.016%" x2="0%" y1="22.966%" y2="100%">
          <stop offset="0%" stopOpacity=".1" />
          <stop offset="100%" stopColor="#FF6663" stopOpacity="0" />
        </linearGradient>
        <path
          id="n"
          d="M17.637 8.847A17.188 17.188 0 0 1 7.248 19.272a5.648 5.648 0 0 1-.509-.689L.199 8.168 12.646.043l4.28 7.328c.28.478.517.972.712 1.476z"
        />
        <path
          id="p"
          d="M129.348 50.64c-7.563 0-14.077 26.83-14.077 29.559 0 1.819-12.59 8.776-37.77 20.871 1.537.334 2.78 1.51 3.726 3.526.946 2.016 2.033 3.118 3.26 3.306 28.335-9.79 43.288-15.678 44.861-17.664 2.36-2.978 9.066-12.527 9.312-24.284.247-11.757-1.748-15.314-9.312-15.314z"
        />
        <path
          id="q"
          d="M121.361 38.226c-4.626 11.065-4.506 25.357 1.38 38.687 5.962 13.366 39.182 28.129 29.19-3.223-10.333-31.47-6.668-42.456-14.71-57.11C129.16 2.1 115.972-4.16 97.687 3.454c-17.938 7.667 28.043 23.721 23.674 34.772z"
        />
        <path
          id="r"
          d="M96.078 19.804c.383 0 10.641 6.333 24.364 7.139 1.46-1.126 5.03-1.126 5.286 3.684.194 3.414-.971 7.037-3.907 7.37-1.864 6.418-4.82 11.655-12.642 12.895-7.822 1.24-12.77-4.135-14.48-7.6-2.135-3.415-4.052-17.012-3.908-18.422 2.296-1.115 4.76-5.066 5.287-5.066z"
        />
        <linearGradient id="s" x1="100%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stopOpacity=".2" />
          <stop offset="100%" stopColor="#FF6663" stopOpacity="0" />
        </linearGradient>
        <path
          id="t"
          d="M55.357 178.77c-4.683-6.982 15.187-13.563 15.75-20.442.375-4.586.903-7.193 1.584-7.822l8.672-1.037c.016 6.144.71 10.695 2.083 13.653 2.058 4.437-.712 9.258-7.787 9.625-7.076.367-15.619 13.004-20.302 6.023z"
        />
        <path
          id="u"
          d="M125.732 124.12H97.66l-13.708 30.87c-1.43 1.653-3.807 2.48-7.129 2.48-3.322 0-5.41-.827-6.267-2.48 1.989-34.504 7.736-52.803 17.24-54.896 21.95-4.835 41.14-5.876 57.571-3.124 1.273 18.1-5.272 27.15-19.634 27.15z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F2F2F4"
          d="M70.548 9.358c103.848-42.409 178.156 78.49 284.71 78.467 106.768.023 106.768 85.856 80.626 111.58-17.647 17.366-37.924 27.564-60.83 30.595H38.515C-9.159 111.226 1.52 37.679 70.547 9.358z"
        />
        <path
          fill="#F2F2F4"
          d="M271.756 44.843H438.9s4.53-18.066-27.207-12.044c-31.736 6.022-26.769-10.969-45.763-14.891-18.994-3.923-28.097 12.062-57.053 3.922-28.955-8.14-50.243 23.013-37.12 23.013z"
        />
        <path
          fill="#DFE4EB"
          d="M0 230c0-17.77 16.895-30.955 34.914-30.955 11.643 0 21.968 5.641 28.392 14.339 3.81-2.9 8.564-4.62 13.72-4.62 5.58 0 10.69 2.015 14.64 5.358 4.104-11.714 15.257-20.116 28.373-20.116 4.388 0 8.555.94 12.313 2.63 3.06-6.852 9.933-11.628 17.922-11.628 9.112 0 16.773 6.213 18.978 14.634a26.15 26.15 0 0 1 3.518-.237c5.993 0 11.51 2.034 15.9 5.45 5.356-8.874 15.092-14.808 26.213-14.808 10.706 0 20.128 5.5 25.595 13.828a21.141 21.141 0 0 1 12.558-4.11c7.907 0 14.805 4.322 18.46 10.732 4.411-6.683 11.987-11.092 20.593-11.092 9.33 0 17.45 5.182 21.637 12.825 5.403-10.805 16.572-18.224 29.474-18.224 9.275 0 17.655 3.834 23.64 10.004 4.306-6.054 11.378-10.004 19.373-10.004a23.666 23.666 0 0 1 15.754 5.976c5.505-8.982 15.412-14.974 26.718-14.974 17.295 0 31.315 14.02 31.315 31.314 0 4.905-1.23 9.545-3.24 13.678H0z"
        />
        <g fill="#B6C5D9">
          <path d="M127.778 113.02h204.444c.597 0 1.08.484 1.08 1.08v10.798a1.08 1.08 0 0 1-1.08 1.08H127.778a1.08 1.08 0 0 1-1.08-1.08V114.1c0-.596.483-1.08 1.08-1.08zM127.778 129.937h204.444c.597 0 1.08.484 1.08 1.08v10.798a1.08 1.08 0 0 1-1.08 1.08H127.778a1.08 1.08 0 0 1-1.08-1.08v-10.798c0-.596.483-1.08 1.08-1.08zM127.778 146.495h204.444c.597 0 1.08.483 1.08 1.08v10.797a1.08 1.08 0 0 1-1.08 1.08H127.778a1.08 1.08 0 0 1-1.08-1.08v-10.798c0-.596.483-1.08 1.08-1.08zM116.98 166.651h226.04c.597 0 1.08.483 1.08 1.08v10.798a1.08 1.08 0 0 1-1.08 1.08H116.98a1.08 1.08 0 0 1-1.08-1.08v-10.798c0-.597.483-1.08 1.08-1.08z" />
          <path d="M147.214 113.02h3.6v53.631h-3.6zM309.186 113.02h3.6v53.631h-3.6zM138.936 179.609h11.878v49.311a1.08 1.08 0 0 1-1.08 1.08h-9.718a1.08 1.08 0 0 1-1.08-1.08V179.61zM309.186 179.609h11.878v49.311a1.08 1.08 0 0 1-1.08 1.08h-9.718a1.08 1.08 0 0 1-1.08-1.08V179.61z" />
        </g>
        <g transform="translate(148 51)">
          <use fill="#232F61" xlinkHref="#a" />
          <use fill="url(#b)" xlinkHref="#a" />
          <g transform="rotate(-100 26.973 106.032)">
            <use fill="#5678FB" xlinkHref="#c" />
            <use fill="url(#d)" xlinkHref="#c" />
          </g>
          <use fill="#232F61" xlinkHref="#e" />
          <use fill="url(#b)" xlinkHref="#e" />
          <use fill="#5678FB" xlinkHref="#f" />
          <use fill="url(#d)" xlinkHref="#f" />
          <use fill="url(#g)" xlinkHref="#f" />
          <use fill="#FFC62C" xlinkHref="#h" />
          <use fill="url(#i)" xlinkHref="#h" />
          <g transform="translate(19.307 81.289)">
            <path
              fill="#D6DFEA"
              d="M2.86 0h37.523l8.575 35.233H8.468a.858.858 0 0 1-.832-.656L.082 3.543A2.865 2.865 0 0 1 2.86 0z"
            />
            <ellipse cx="23.215" cy="17.732" fill="#F2F5F9" rx="2.528" ry="2.533" />
            <path
              fill="#B6C5D9"
              d="M49.167 30.936h25.227a2.147 2.147 0 1 1 0 4.297H46.78v-2.349L39.107 1.391A1.125 1.125 0 0 1 40.199 0c.84 0 1.57.575 1.77 1.391l7.198 29.545z"
            />
          </g>
          <g transform="translate(109.755 48.128)">
            <mask id="l" fill="#fff">
              <use xlinkHref="#j" />
            </mask>
            <use fill="#5678FB" xlinkHref="#j" />
            <use fill="url(#d)" xlinkHref="#j" />
            <path
              fill="url(#k)"
              d="M34.248 34.215c.782 3.88 1.303 8.062 1.493 12.532.431 10.8-2.003 16.986-5.718 20.057-4.893 1.04-9.918 1.275-15.076.706-3.294-1.45-6.332-3.635-8.532-6.004 2.53-21.66 8.538-32.49 18.027-32.49 3.8 0 7.143 2.055 9.806 5.2z"
              mask="url(#l)"
            />
            <ellipse cx="-.69" cy="2.188" fill="#FFF" mask="url(#l)" rx="7.585" ry="8.405" />
            <ellipse cx="10.573" cy="5.872" fill="#FFF" mask="url(#l)" rx="7.585" ry="8.405" />
          </g>
          <g transform="translate(105.731 33.851)">
            <use fill="#FFC62C" xlinkHref="#m" />
            <use fill="url(#i)" xlinkHref="#m" />
            <use fill="url(#o)" xlinkHref="#n" />
          </g>
          <use fill="#5678FB" xlinkHref="#p" />
          <use fill="url(#d)" xlinkHref="#p" />
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M129.348 50.065a.345.345 0 0 1 0 .69c-3.127 0-6.483 5.127-9.55 13.53-2.193 6.004-4.182 13.914-4.182 15.683 0 1.119-2.867 3.156-8.874 6.482-4.733 2.62-11.392 6.005-19.985 10.158l-.16.077c-1.897.916-2.23.31-.354-.596l.173-.083c8.599-4.155 15.264-7.542 19.993-10.16 5.588-3.095 8.518-5.177 8.518-5.878 0-1.871 2.004-9.845 4.223-15.92 3.178-8.705 6.633-13.983 10.198-13.983z"
          />
          <g>
            <use fill="#232F61" xlinkHref="#q" />
            <use fill="url(#b)" xlinkHref="#q" />
          </g>
          <g>
            <use fill="#FFC62C" xlinkHref="#r" />
            <use fill="url(#i)" xlinkHref="#r" />
          </g>
          <ellipse cx="121.996" cy="32.184" fill="url(#s)" rx="2.014" ry="2.018" />
          <g>
            <use fill="#5678FB" xlinkHref="#t" />
            <use fill="url(#d)" xlinkHref="#t" />
          </g>
          <g>
            <use fill="#232F61" xlinkHref="#u" />
            <use fill="url(#b)" xlinkHref="#u" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default EmptyStateWaiting
