import React from 'react'

export const IconPill: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <path
          id="a"
          d="M18.584 6.056A3.591 3.591 0 0 0 16.036 5c-.922 0-1.845.352-2.548 1.056l-7.433 7.432a3.604 3.604 0 1 0 5.097 5.096l7.433-7.433a3.603 3.603 0 0 0 0-5.095zm-1.056 4.04l-3.19 3.19-2.984-2.983 3.19-3.191a2.096 2.096 0 0 1 1.492-.618 2.112 2.112 0 0 1 1.492 3.602z"
        />
      </g>
    </svg>
  )
}
