import React from 'react'

export const QuestionMark: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="52" viewBox="0 0 35 52" fill="none">
      <path d="M17.4996 51.9995C21.0063 51.9995 23.8491 49.1671 23.8491 45.6731C23.8491 42.1791 21.0063 39.3467 17.4996 39.3467C13.9928 39.3467 11.15 42.1791 11.15 45.6731C11.15 49.1671 13.9928 51.9995 17.4996 51.9995Z" />
      <path d="M0 14.6588L0.619469 12.6528C2.63274 4.93769 9.4469 0 17.3451 0C26.792 0 35 7.40653 35 16.9733C35 24.6884 29.7345 31.1691 22.4558 33.1751V36.5697H12.3894V30.5519C12.3894 27.1573 14.8673 24.2255 18.2743 23.9169C21.9912 23.454 25.0885 20.6766 25.0885 16.9733C25.0885 12.8071 21.3717 10.0297 17.3451 10.0297C13.9381 10.0297 11.1504 11.8813 10.2212 15.276L9.60177 17.2819L0 14.6588Z" />
    </svg>
  )
}
