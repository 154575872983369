import React from 'react'

export const IconCameraOff: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M5.343 8.09c.049 1.375 1.193 2.518 2.567 2.567L5.343 8.09zM14 3.333h-2.391L9.804 1.528a.66.66 0 0 0-.47-.195H6.666h-.001a.667.667 0 0 0-.471.195L4.389 3.333h-.115L2.469 1.528l-.943.943 12 12 .943-.943-.241-.241a.665.665 0 0 0 .437-.621V3.999a.667.667 0 0 0-.667-.667zM9.279 8.337L7.663 6.72A1.23 1.23 0 0 1 8 6.667c.71 0 1.333.623 1.333 1.333 0 .116-.022.228-.054.337zm1.002 1a2.56 2.56 0 0 0 .386-1.336V8c0-1.445-1.221-2.667-2.667-2.667a2.59 2.59 0 0 0-1.338.386L5.276 4.333l1.667-1.667h2.115l1.805 1.805a.66.66 0 0 0 .47.195h2.001v7.333h-.391l-2.662-2.663z" />
      <path d="M2 13.333h8.586L9.253 12H2.667V5.414L1.334 4.081v8.586c0 .368.298.667.667.667z" />
    </svg>
  )
}
