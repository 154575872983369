import React from 'react'

export const IconMenu: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <path d="M9.534 11.02h8.082c.54 0 .99.45.99.99s-.45.99-.99.99H9.534c-.54 0-.99-.45-.99-.99 0-.56.432-.99.99-.99zm3.492-5.67h4.571c.54 0 .99.45.99.99s-.45.99-.99.99h-4.571c-.54 0-.99-.45-.99-.99s.45-.99.99-.99zM6.384 16.67h11.232c.54 0 .99.45.99.99s-.45.99-.99.99H6.384c-.54 0-.99-.45-.99-.99s.432-.99.99-.99z" />
      </g>
    </svg>
  )
}
